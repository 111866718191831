import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonColors } from 'src/utils/constants';
import { deleteById, Entities, fetchAll, getEntities, patch } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ErrorMessage from 'src/components/misc/error-message';
import { connect } from 'react-redux';
import { toggleFormatter } from 'src/utils/formatters';
import { deleteTripCategory } from 'src/actions/trip-categories';
import ParentForm from 'src/components/misc/parent-form';
import TripCategoryForm from 'src/components/trips/admin/category/TripCategoryForm';
import { withTranslation } from 'react-i18next';
import readOnlyField from 'src/components/misc/ReadOnlyField';
import Toggle from 'material-ui/Toggle';
import store from 'src/store/store';

class AdminTripCategoryAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
            renderSelection: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.TRIP_CATEGORY);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { categories } = nextProps;

        if (!this.state.startedFetching && categories.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(categories.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.categories, this.props.categories, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { categories, t } = this.props;
        const finishFetching = this.state.startedFetching && !categories.isFetching;

        const tableData = [];
        if (finishFetching && categories.items.length > 0) {
            categories.items.map(category => {
                tableData.push({
                    id: category.id,
                    name: category.name,
                    group: category.group,
                    visible: category.visible,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('common_phrases.categories')}
                    {/*<button*/}
                    {/*    className="btn btn-xs btn-link nav-link pull-right"*/}
                    {/*    onClick={() => {*/}
                    {/*        this.table.reset();*/}
                    {/*        this.props.openModal({*/}
                    {/*            component: ParentForm,*/}
                    {/*            componentProps: {*/}
                    {/*                isCreate: true,*/}
                    {/*                mainEntityProps: {*/}
                    {/*                    entity: Entities.TRIP_CATEGORY,*/}
                    {/*                    id: undefined,*/}
                    {/*                },*/}
                    {/*                childForm: {*/}
                    {/*                    component: TripCategoryForm,*/}
                    {/*                },*/}
                    {/*            },*/}
                    {/*            title: t('category.new_category'),*/}
                    {/*        });*/}
                    {/*    }}>*/}
                    {/*    <span className="glyphicon glyphicon-plus" />*/}
                    {/*    &nbsp;{t('category.new_category')}*/}
                    {/*</button>*/}
                </legend>

                {(() => {
                    if (categories.error) return <ErrorMessage object={categories} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign(
                                    {},
                                    this.tableHelper.getOptions(finishFetching, this.state.page, tableData.length),
                                )}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps(this.state.selected))}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="group" dataSort={true}>
                                    {t('group')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="visible"
                                    width="110"
                                    dataSort={true}
                                    dataFormat={(cell, row) => <Toggle toggled={row['visible']} />}>
                                    {t('companies.visible')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    onProceedDelete(category) {
        this.props.deleteTripCategory(category.id);
    }

    openConfirmDeleteDialog(category) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: category,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('category.confirm_delete_category'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: category.name,
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, category) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.TRIP_CATEGORY,
                                    id: category.id,
                                },
                                childForm: {
                                    component: TripCategoryForm,
                                },
                            },
                            title: category.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                {/*<button*/}
                {/*    type="button"*/}
                {/*    className="btn btn-xs transparent"*/}
                {/*    title={t('user_functionality.delete')}*/}
                {/*    onClick={() => this.openConfirmDeleteDialog(category)}>*/}
                {/*    <span className="glyphicon glyphicon-trash text-danger" />*/}
                {/*</button>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: getEntities(state, Entities.TRIP_CATEGORY),
    };
};

export default withTranslation()(
    connect(mapStateToProps, { fetchAll, deleteById, patch, deleteTripCategory })(
        composeModalActions(AdminTripCategoryAdministration),
    ),
);
