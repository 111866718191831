import React from 'react';
import { connect } from 'react-redux';
import { List, ListItem, makeSelectable } from 'material-ui/List';
import { push, RouterAction } from 'react-router-redux';
import { LocationDescriptor } from 'history';
import { LinkNavigation } from 'src/types/typings';
import { TOMCAT_URL } from 'src/common/index';

let SelectableList = makeSelectable(List);

/**
 * Describes a component route for sidebar navigation
 * Use the optional children array for nested menu items
 */
export interface ComponentRoute {
    path: string;
    icon: React.ReactElement<any>;
    primaryText: React.ReactNode;
    secondaryText?: string;
    initiallyOpen?: boolean;
    children?: ComponentRoute[];
    disabled?: boolean;
    link?: LinkNavigation;
}

export interface ComponentRoutes extends Array<ComponentRoute> {}

interface MapDispatchToProps {
    push: (nextLocation: LocationDescriptor) => RouterAction;
}

interface OwnProps {
    routes: ComponentRoutes;
}

interface State {
    selectedPath: string;
}

type Props = OwnProps & MapDispatchToProps;

class Navigation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedPath: window.location.pathname + window.location.search,
        };
    }

    public render() {
        const { routes } = this.props;

        return (
            <SelectableList
                value={this.state.selectedPath}
                onChange={(event: any, index: any) => this.setState({ selectedPath: index })}>
                {routes.map(this.renderListItem)}
            </SelectableList>
        );
    }

    private renderListItem = (componentRoute: ComponentRoute): any => {
        const { push } = this.props;

        return (
            <ListItem
                disabled={componentRoute.disabled}
                key={componentRoute.path}
                value={componentRoute.path}
                style={{ fontSize: '14px' }}
                primaryText={componentRoute.primaryText}
                secondaryText={componentRoute.secondaryText}
                leftIcon={componentRoute.icon}
                onClick={componentRoute.link ? undefined : () => push(componentRoute.path)}
                target={componentRoute.link ? componentRoute.link.target : undefined}
                href={componentRoute.link ? componentRoute.link.href : undefined}
                initiallyOpen={componentRoute.initiallyOpen === true}
                nestedItems={
                    componentRoute.children && componentRoute.children.length > 0
                        ? componentRoute.children.map(this.renderListItem)
                        : []
                }
            />
        );
    };
}

export default connect<null, MapDispatchToProps, OwnProps>(null, { push })(Navigation);
