import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Entities, fetchById, getEntities, getUpdatedEntity, patch, Projection, save, search } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import CompanyEditForm from 'src/components/company/company-edit-form';
import { setSliderIndex } from 'src/actions/form';

class CompanyEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, isTripCompany, id } = this.props;
        const entity = isTripCompany ? Entities.TRIP_COMPANY : Entities.BUS_COMPANY;

        if (isTripCompany) {
            this.props.search(Entities.TRIP_ATTRIBUTE, 'findAllByVisibleTrueOrderByNameAsc');
            this.props.search(Entities.TRIP_CATEGORY, 'findAllByVisibleTrueOrderByNameAsc');
        }

        if (!isCreate) this.props.fetchById(id, entity, 0, Projection.FULL);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { company } = nextProps;

        if (!this.state.startedFetching && company.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { company, isCreate, isAdmin, isTripCompany, tripCategories, tripAttributes, busCompanyTos, t } =
            this.props;
        const finishFetching =
            this.state.startedFetching &&
            !company.isFetching &&
            !tripCategories.isFetching &&
            !tripAttributes.isFetching;

        if (finishFetching) {
            if (company.error) return <ErrorMessage object={company} />;
            else {
                let initialValues;

                if (!isCreate && company.content) {
                    let specificCompanyFields = {};

                    if (isTripCompany) {
                        specificCompanyFields.subtitle = company.content.subtitle;
                        specificCompanyFields.shortDescription = company.content.shortDescription;
                        specificCompanyFields.recommendedDurationOfStayInMinutes =
                            company.content.recommendedDurationOfStayInMinutes;
                        specificCompanyFields.bookingConditions = company.content.bookingConditions;
                        specificCompanyFields.productInformation = company.content.productInformation;

                        specificCompanyFields.attributes = tripAttributes.items
                            .filter(attribute => company.content.attributes.includes(attribute.id))
                            .map(attribute => attribute._links.self.href);

                        specificCompanyFields.categories = tripCategories.items
                            .filter(category => company.content.categories.includes(category.id))
                            .map(category => category._links.self.href);
                    } else {
                        specificCompanyFields.ftpCompanyId = company.content.ftpCompanyId;
                        specificCompanyFields.importReleaseMode = company.content.importReleaseMode;
                        specificCompanyFields.ratioClient = company.content.ratioClient;
                        specificCompanyFields.ratioApiKey = company.content.ratioApiKey;
                        specificCompanyFields.ratioCreateTransfer = company.content.ratioCreateTransfer;
                        specificCompanyFields.ratioEinsatzart = company.content.ratioEinsatzart;
                        specificCompanyFields.ratioDatevKonto = company.content.ratioDatevKonto;
                        specificCompanyFields.ratioCreateZahlung = company.content.ratioCreateZahlung;
                        specificCompanyFields.ratioZahlungSoll = company.content.ratioZahlungSoll;
                        specificCompanyFields.ratioZahlungErfasstDurch = company.content.ratioZahlungErfasstDurch;
                        specificCompanyFields.turistaCompanyId = company.content.turistaCompanyId;
                        specificCompanyFields.turistaUsername = company.content.turistaUsername;
                        specificCompanyFields.turistaPassword = company.content.turistaPassword;
                        specificCompanyFields.integrationOnlyBuses = company.content.integrationOnlyBuses;
                    }

                    initialValues = {
                        id: company.content.id,
                        companyName: company.content.companyName,
                        registeredCompanyName: company.content.registeredCompanyName,
                        location: company.content.location,
                        language: company.content.language,
                        logo: company.content.logo,
                        uidNumber: company.content.uidNumber,
                        registrationNumber: company.content.registrationNumber,
                        socialCapital: company.content.socialCapital,
                        agb: company.content.agb,
                        paymentConditions: company.content.paymentConditions,
                        reversalConditions: company.content.reversalConditions,
                        confirmationConclusion: company.content.confirmationConclusion,
                        contactData: {
                            gender: company.content.contactData.gender,
                            firstName: company.content.contactData.firstName,
                            lastName: company.content.contactData.lastName,
                            country: company.content.contactData.country,
                            city: company.content.contactData.city,
                            postCode: company.content.contactData.postCode,
                            street: company.content.contactData.street,
                            phone: company.content.contactData.phone,
                            email: company.content.contactData.email,
                            homepage: company.content.contactData.homepage,
                        },
                        invoiceEmail: company.content.invoiceEmail,
                        description: company.content.description,
                        freeCancellation: company.content.freeCancellation,
                        freeCancellationDays: company.content.freeCancellationDays,
                        documents: company.content.documents,
                        onlinePaymentSettings: company.content.onlinePaymentSettings,
                        currency: company.content.currency,
                        bookingFee: company.content.bookingFee,
                        registrationTOS: company.content.registrationTOS,
                        accountingNumberInvoice: company.content.accountingNumberInvoice,
                        emergencyContactNumber: company.content.emergencyContactNumber,
                        ...specificCompanyFields,
                    };
                    console.log(initialValues, 'myinitvals');
                } else {
                    let specificCompanyFields = {};

                    if (isTripCompany) {
                        specificCompanyFields.attributes = [];
                        specificCompanyFields.categories = [];
                    }
                    initialValues = {
                        contactData: {
                            country: 'AT',
                        },
                        company: {
                            availableRegistrationTOSVersions: busCompanyTos,
                        },
                        ...specificCompanyFields,
                    };
                }

                return (
                    <CompanyEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        company={company.content}
                        tripAttributes={tripAttributes.items}
                        tripCategories={tripCategories.items}
                        isAdmin={isAdmin}
                        isCreate={isCreate}
                        isTripCompany={isTripCompany}
                        setSliderIndex={this.props.setSliderIndex}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                        readonly={this.props.readonly}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, isTripCompany, handleClose, patch, save } = this.props;
        const entity = isTripCompany ? Entities.TRIP_COMPANY : Entities.BUS_COMPANY;

        if (isCreate) save(data, entity, 0, Projection.FULL);
        else patch(data, entity, 0, Projection.FULL);

        handleClose();
    }
}

const mapStateToProps = (state, ownProps) => {
    const { isTripCompany } = ownProps;
    const entity = isTripCompany ? Entities.TRIP_COMPANY : Entities.BUS_COMPANY;

    return {
        company: getUpdatedEntity(state, entity),
        tripCategories: getEntities(state, Entities.TRIP_CATEGORY),
        tripAttributes: getEntities(state, Entities.TRIP_ATTRIBUTE),
        selectedSliderIndex: state.selectedSliderIndex,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        search,
        patch,
        save,
        setSliderIndex,
    },
    null,
    { withRef: true },
)(CompanyEditModal);
