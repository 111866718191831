import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Field } from 'redux-form';
import { renderCheckbox } from 'src/components/misc/redux-form-helpers';
import Checkbox from 'material-ui/Checkbox';

class SelectAndConfirmDialog extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            availableInvoiceFormats: this.props.availableInvoiceFormats,
        };
    }

    proceedDialog() {
        const { onProceedDialog, event } = this.props;

        if (onProceedDialog) onProceedDialog(this.state.availableInvoiceFormats);

        this.close();
    }

    cancelDialog() {
        const { onCancelDialog, event } = this.props;

        if (onCancelDialog && event) onCancelDialog(event);

        this.close();
    }

    close() {
        const { handleClose, closeDrawer } = this.props;

        if (closeDrawer) closeDrawer();
        handleClose();
    }

    render() {
        console.log(this.props, 'this.roprs');
        const {
            bodyText,
            proceedText,
            cancelText,
            proceedButtonColor,
            cancelButtonColor,
            isNotification,
            availableInvoiceFormats,
        } = this.props;
        console.log(availableInvoiceFormats);
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 voffset">{bodyText}</div>
                </div>

                <div className="row">
                    <div className="col-md-12 voffset">
                        {availableInvoiceFormats.map(format => (
                            <>
                                <Checkbox
                                    label={format}
                                    checked={this.state.availableInvoiceFormats.includes(format)}
                                    onCheck={(event, value) => {
                                        this.state.availableInvoiceFormats.includes(format)
                                            ? this.setState({
                                                  availableInvoiceFormats: this.state.availableInvoiceFormats.filter(
                                                      it => it !== format,
                                                  ),
                                              })
                                            : this.setState({
                                                  availableInvoiceFormats: [
                                                      ...this.state.availableInvoiceFormats,
                                                      format,
                                                  ],
                                              });
                                        //this.setState({ isPartner: !this.state.isPartner });
                                    }}
                                    disabled={false}
                                    title={format}
                                />
                            </>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 voffset">
                        <div className="form-group pull-right">
                            {!isNotification && (
                                <RaisedButton
                                    onClick={this.cancelDialog}
                                    label={cancelText}
                                    backgroundColor={cancelButtonColor}
                                    labelColor="#ffffff"
                                    className="confirm-dialog-2ndButton"
                                />
                            )}
                            <RaisedButton
                                onClick={this.proceedDialog}
                                label={proceedText}
                                disabled={this.state.availableInvoiceFormats.length === 0}
                                backgroundColor={proceedButtonColor}
                                className="pull-right"
                                labelColor="#ffffff"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectAndConfirmDialog;
