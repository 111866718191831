import { connect } from 'react-redux';

import { deleteById, Entities, search, getEntities, patch } from 'src/common/index';
import AdminCompanyAdministration from 'src/components/admin/companies/admin-company-administration';
import { fetchAdminNews } from 'src/actions/admin-news';

const mapStateToProps = state => {
    return {
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
        operatorAccounts: getEntities(state, Entities.OPERATOR),
    };
};

const AdminCompanyAdministrationContainer = connect(mapStateToProps, { fetchAdminNews, search, deleteById, patch })(
    AdminCompanyAdministration,
);

export default AdminCompanyAdministrationContainer;
