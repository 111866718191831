import { asyncRequest, Entities, getEntities, TOMCAT_URL, fetchAll } from 'src/common/index';
import HttpStatus from 'http-status-codes';
import { CircularProgress } from 'material-ui';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { addNotification } from 'src/actions/notifications';
import CustomerAdministrationImportForm from 'src/components/administration/customer-administration-import-form';
import TableHelper from 'src/utils/table-helper';

/**
 * In a dev environment we use Ratio's demo API key, therefore switch to Ratio at Interne Testflotte to see the results.
 */
class CustomerAdministrationImportModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            selectedCustomer: undefined,
        };

        this.tableHelper = new TableHelper({ onRowClick: this.onSelectCustomer });
    }

    componentDidMount() {
        this.props.fetchAll(Entities.CUSTOMER_GROUP);
    }

    didFormChange() {
        return !this.childForm.getWrappedInstance().pristine;
    }

    callSubmit() {
        return this.childForm.getWrappedInstance().submit();
    }

    render() {
        const { ratioCustomers, t, customerGroups } = this.props;

        const tableData = [];
        if (!ratioCustomers.isFetching && ratioCustomers.items.length > 0) {
            ratioCustomers.items.map(({ customer, id }) => {
                tableData.push({
                    id,
                    email: customer.email,
                    firstName: customer.firstname,
                    lastName: customer.lastname,
                    customerNumber: customer.customernumber,
                    city: customer.city,
                    postCode: customer.postcode,
                    street: customer.street,
                    country: customer.country,
                    address: this.getCustomerAddress(customer),
                });
            });
        }

        return (
            <>
                <CustomerAdministrationImportForm
                    ref={ref => (this.childForm = ref)}
                    t={t}
                    onSubmit={this.handleSubmit}
                    selectedCustomer={this.state.selectedCustomer}
                    customerGroups={customerGroups}
                />
                <BootstrapTable
                    ref={ref => (this.table = ref)}
                    data={tableData}
                    striped={true}
                    hover={true}
                    condensed={true}
                    pagination={true}
                    options={Object.assign({}, this.tableHelper.getOptions(), this.getNoDataText())}
                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}>
                    <TableHeaderColumn dataField="id" hidden={true} isKey={true} />
                    <TableHeaderColumn dataField="city" hidden={true} />
                    <TableHeaderColumn dataField="postCode" hidden={true} />
                    <TableHeaderColumn dataField="street" hidden={true} />
                    <TableHeaderColumn dataField="country" hidden={true} />
                    <TableHeaderColumn columnTitle={true} dataField="email" dataSort={true}>
                        {t('contact_data.email')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="firstName" dataSort={true}>
                        {t('contact_data.first_name')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="lastName" dataSort={true}>
                        {t('contact_data.last_name')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true} width="35%">
                        {t('contact_data.address')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="customerNumber" dataSort={true} width="115">
                        {t('contact_data.customer_number')}
                    </TableHeaderColumn>
                </BootstrapTable>
            </>
        );
    }

    onSelectCustomer(customer) {
        if (this.state.selectedCustomer && customer.id === this.state.selectedCustomer.id) {
            this.tableHelper.setSelectedRow();
            this.setState({ selectedCustomer: undefined });
        } else {
            this.tableHelper.setSelectedRow(customer.id);
            this.setState({
                selectedCustomer: customer,
            });
        }
    }

    handleSubmit(data) {
        this.createRatioCustomer(data);
    }

    async createRatioCustomer(data) {
        const { customerGroupId, transferNumber = '' } = data;
        const { t } = this.props;

        try {
            await asyncRequest({
                method: 'POST',
                endpoint: `${TOMCAT_URL}api/${Entities.RATIO_CUSTOMER.repository}/create`,
                body: {
                    ...this.state.selectedCustomer,
                    transferNumber,
                    customerGroupId,
                },
            });
            this.props.handleClose();
            this.props.fetchCustomers();
        } catch (error) {
            if (error.status === HttpStatus.CONFLICT) {
                this.props.addNotification(
                    t('customer_details.duplicate_email'),
                    t('customer_details.duplicate_email_message'),
                    'error',
                    'tr',
                );
            }

            if (error.status === HttpStatus.BAD_REQUEST) {
                this.props.addNotification(
                    t('customer_details.missing_email'),
                    t('customer_details.missing_email_message'),
                    'error',
                    'tr',
                );
            }
        }
    }

    getNoDataText() {
        const { ratioCustomers, t } = this.props;
        const options = { noDataText: t('no_entries') };
        if (ratioCustomers.isFetching) {
            return {
                ...options,
                noDataText: <CircularProgress />,
            };
        }

        return options;
    }

    getCustomerAddress(customer) {
        const addressParts = [customer.street, customer.postcode + ' ' + customer.city, customer.country];
        return addressParts.filter(part => part).join(', ');
    }
}

const mapStateToProps = state => {
    return {
        ratioCustomers: getEntities(state, Entities.RATIO_CUSTOMER),
        customerGroups: getEntities(state, Entities.CUSTOMER_GROUP),
    };
};

export default connect(mapStateToProps, { addNotification, fetchAll }, null, {
    withRef: true,
})(CustomerAdministrationImportModal);
