import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { asyncRequest, deleteById, Entities, patch, save, TOMCAT_URL } from 'src/common/index';
import { selectEvent } from 'src/actions/events';
import { addErrorNotification, addNotification } from 'src/actions/notifications';
import { replace } from 'react-router-redux';
import {
    acceptExchangedTask,
    acceptOffer,
    cancelDisposeExternTask,
    declineExchangedTask,
    declineOffer,
    deleteOffer,
    disposeTaskExtern,
    duplicateTask,
    fetchTasksByCompany,
    removeTaskFromExchange,
} from 'src/actions/tasks';
import HttpStatus from 'http-status-codes';
import { getOwnBookingIntegration } from 'src/selectors/integrations';
import { fetchOwnedBookingIntegration } from 'src/actions/integrations';

export default function composeTaskActions(ChildComponent) {
    class TaskActionComponent extends Component {
        constructor(props) {
            super(props);
            autoBind(this);
        }

        render() {
            return (
                <ChildComponent
                    {...this.props}
                    {...this.state}
                    onProceedAcceptTask={this.onProceedAcceptTask}
                    onProceedDeclineTask={this.onProceedDeclineTask}
                    onProceedDeletePendingTask={this.onProceedDeletePendingTask}
                    onProceedDeleteTask={this.onProceedDeleteTask}
                    onProceedDuplicateTask={this.onProceedDuplicateTask}
                    onProceedRemoveDrivers={this.onProceedRemoveDrivers}
                    onProceedCancelReservation={this.onProceedCancelReservation}
                    onProceedReceivedTask={this.onProceedReceivedTask}
                    onProceedDisposeTaskExtern={this.onProceedDisposeTaskExtern}
                    onProceedCancelDisposeExternTask={this.onProceedCancelDisposeExternTask}
                    onProceedExportToCsBus={this.onProceedExportToCsBus}
                    onProceedDeclineOffer={this.onProceedDeclineOffer}
                    onProceedDeleteOffer={this.onProceedDeleteOffer}
                    onProceedAcceptOffer={this.onProceedAcceptOffer}
                    onResendOffer={this.onResendOffer}
                />
            );
        }

        onProceedCancelDisposeExternTask(task) {
            this.props.cancelDisposeExternTask(task.id, this.props.selectedCompany);
        }

        onProceedDisposeTaskExtern(task) {
            this.props.disposeTaskExtern(task.id, this.props.selectedCompany);
        }

        onProceedCancelReservation(task) {
            this.props.patch(
                {
                    id: task.id,
                    cancelled: true,
                },
                Entities.TASK,
                this.props.selectedCompany,
                Entities.TASK.projection,
            );
        }

        onProceedReceivedTask(task) {
            this.props.patch(
                {
                    id: task.id,
                    received: true,
                },
                Entities.TASK,
                this.props.selectedCompany,
                Entities.TASK.projection,
                this.onSuccessReceivedTask,
                'receivedBooking',
            );
        }

        onProceedRemoveDrivers(props) {
            const { task, onSuccessRemoveDrivers } = props;

            const taskPatch = {
                id: task.id,
                firstDriver: null,
                secondDriver: null,
            };

            this.props.patch(
                taskPatch,
                Entities.TASK,
                this.props.selectedCompany,
                Entities.TASK.projection,
                onSuccessRemoveDrivers,
            );
        }

        onProceedDuplicateTask(task) {
            this.props.duplicateTask(task.id, this.props.selectedCompany);
        }

        onProceedDeleteTask(task) {
            this.props.deleteById(task.id, Entities.TASK, this.props.selectedCompany);
        }

        onProceedDeclineTask(task) {
            this.props.declineExchangedTask(task.id, this.props.selectedCompany, this.processDeclineTaskSuccess);
        }

        onProceedAcceptOffer(task) {
            this.props.acceptOffer(
                task.offerDetails.bookingToken,
                this.props.selectedCompany,
                this.processOfferSuccess.apply(this, ['accept']),
            );
        }

        onProceedDeclineOffer(task) {
            this.props.declineOffer(
                task.offerDetails.bookingToken,
                this.props.selectedCompany,
                this.processOfferSuccess.apply(this, ['decline']),
            );
        }

        onProceedDeleteOffer(task) {
            this.props.deleteOffer(
                task.offerDetails.bookingToken,
                this.props.selectedCompany,
                this.processOfferSuccess.apply(this, ['delete']),
            );
        }

        async onResendOffer(task) {
            const { t, addNotification } = this.props;
            const customerEmail = task.customerContactData.email;
            addNotification(t('common_phrases.loading'), t('offers.offer_resending'), 'info', 'tr');
            try {
                await this.sendOfferToCustomer(task.id, customerEmail);
                this.processOfferSuccess.apply(this, ['resend']);
            } catch (error) {
                addErrorNotification({ code: error.status, message: error.statusText });
            }
        }

        onProceedAcceptTask(task) {
            const { selectedCompany } = this.props;
            this.props.acceptExchangedTask(
                task.id,
                task.exchangedTasks[0].foreignCompanyDetails.id,
                selectedCompany,
                this.processAcceptTaskErrors,
                this.processAcceptTaskSuccess,
            );
        }

        onProceedExportToCsBus(task) {
            this.props.post(
                {
                    id: task.id,
                },
                Entities.TASK,
                this.props.selectedCompany,
                Entities.TASK.projection,
                undefined,
                'exportBookingToCsBus',
            );
        }

        onSuccessReceivedTask(task, status) {
            const { t } = this.props;

            if (status === HttpStatus.CONFLICT)
                this.props.addNotification(
                    t('warning'),
                    t('notifications.task_already_received', {
                        task: task.identifier,
                        email: task.customerContactData.email,
                    }),
                    'warning',
                    'tr',
                );
            else
                this.props.addNotification(
                    t('common_phrases.success'),
                    t('notifications.task_success_received', {
                        task: task.identifier,
                        email: task.customerContactData.email,
                    }),
                    'success',
                    'tr',
                );

            this.props.replace(`/task-administration?filter=BOOKED_BY_CUSTOMER_NOT_RECEIVED`);
        }

        processAcceptTaskSuccess() {
            const { t } = this.props;
            this.props.addNotification(
                t('common_phrases.success'),
                t('notifications.task_success_accept'),
                'success',
                'tr',
            );
        }

        processAcceptTaskErrors(status) {
            const { addNotification, t } = this.props;

            if (status.error.status === HttpStatus.NOT_FOUND) {
                addNotification(t('attention'), t('notifications.task_attention_accept'), 'error', 'tr');
            } else
                addNotification(
                    t('common_phrases.error'),
                    t('notifications.error', { message: status.error.statusText }),
                    'error',
                    'tr',
                );

            this.props.fetchMethodOnError();
        }

        onProceedDeletePendingTask(task) {
            const { selectedCompany } = this.props;

            this.props.removeTaskFromExchange(
                task.id,
                selectedCompany,
                this.processDeletePendingTaskErrors,
                this.processDeletePendingTaskSuccess,
            );
        }

        processDeclineTaskSuccess() {
            const { t } = this.props;
            this.props.addNotification(
                t('common_phrases.success'),
                t('notifications.task_success_decline'),
                'success',
                'tr',
            );
        }

        processOfferSuccess(type) {
            const { t } = this.props;
            this.props.addNotification(
                t('common_phrases.success'),
                t(`notifications.offer_success_${type}`),
                'success',
                'tr',
            );
        }

        processDeletePendingTaskSuccess() {
            const { t } = this.props;
            this.props.addNotification(
                t('common_phrases.success'),
                t('notifications.task_success_delete_pending'),
                'success',
                'tr',
            );
        }

        processDeletePendingTaskErrors(status) {
            const { addNotification, t } = this.props;

            if (status.error.status === HttpStatus.CONFLICT) {
                addNotification(t('attention'), t('notifications.task_attention_delete_pending'), 'error', 'tr');
            } else
                addNotification(
                    t('common_phrases.error'),
                    t('notifications.error', { message: status.error.statusText }),
                    'error',
                    'tr',
                );

            this.props.fetchMethodOnError();
        }

        sendOfferToCustomer = async (id, customerEmail) => {
            const formData = new FormData();
            formData.append('customerEmail', customerEmail);

            await asyncRequest({
                method: 'POST',
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/${id}/sendOfferToCustomer`,
                body: formData,
            });
        };
    }

    const mapStateToProps = state => {
        return {
            selectedCompany: state.selectedCompany,
            fetchMethodOnError: state.fetchMethodOnError,
            integration: getOwnBookingIntegration(state),
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            acceptExchangedTask: (taskId, foreignCompanyId, busCompanyId, errorCallback, successCallback) => {
                dispatch(selectEvent(''));
                dispatch(acceptExchangedTask(taskId, foreignCompanyId, busCompanyId, errorCallback, successCallback));
            },
            declineExchangedTask: (taskId, busCompanyId, successCallback) => {
                dispatch(selectEvent(''));
                dispatch(declineExchangedTask(taskId, busCompanyId, successCallback));
            },
            acceptOffer: (bookingToken, busCompanyId, successCallback) => {
                dispatch(acceptOffer(bookingToken, busCompanyId, successCallback));
            },
            declineOffer: (bookingToken, busCompanyId, successCallback) => {
                dispatch(declineOffer(bookingToken, busCompanyId, successCallback));
            },
            deleteOffer: (bookingToken, busCompanyId, successCallback) => {
                dispatch(deleteOffer(bookingToken, busCompanyId, successCallback));
            },
            removeTaskFromExchange: (taskId, selectedCompany, errorCallback, successCallback) => {
                dispatch(removeTaskFromExchange(taskId, selectedCompany, errorCallback, successCallback));
            },
            fetchTasksByCompany: busCompanyId => {
                dispatch(fetchTasksByCompany(busCompanyId));
            },
            addNotification: (title, message, level, position) => {
                dispatch(addNotification(title, message, level, position));
            },
            deleteById: (id, entity, reducerIndex) => {
                dispatch(deleteById(id, entity, reducerIndex));
                dispatch(selectEvent(''));
            },
            duplicateTask: (taskId, busCompanyId) => {
                dispatch(duplicateTask(taskId, busCompanyId));
            },
            patch: (body, entity, reducerIndex, projection, successCallback, method) => {
                dispatch(patch(body, entity, reducerIndex, projection, successCallback, method));
            },
            post: (body, entity, reducerIndex, projection, successCallback, method) => {
                dispatch(selectEvent(''));
                dispatch(save(body, entity, '', projection, successCallback, method));
            },
            disposeTaskExtern: (taskId, busCompanyId) => {
                dispatch(disposeTaskExtern(taskId, busCompanyId));
            },
            cancelDisposeExternTask: (taskId, busCompanyId) => {
                dispatch(cancelDisposeExternTask(taskId, busCompanyId));
            },
            replace: (location, state) => {
                dispatch(replace(location, state));
            },
            fetchOwnedBookingIntegration: () => {
                dispatch(fetchOwnedBookingIntegration());
            },
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(TaskActionComponent);
}
