import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import _ from 'lodash';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import CompanyTable from 'src/components/admin/buslocations/company-table';
import LegendStats from 'src/components/admin/buslocations/legend-stats';
import ErrorMessage from 'src/components/misc/error-message';
import BusYieldCalendar from 'src/components/admin/bus-yield/bus-yield-calendar';

class BusYield extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            // requested backend data
            availableBuses: {
                entities: [],
                isFetching: true,
                error: undefined,
            },
        };
    }

    componentWillMount() {
        this.loadBusYieldStatistics();
        //this.loadCompaniesWithBuses();
    }

    render() {
        const { i18n, t } = this.props;
        if (!this.state.availableBuses.isFetching) {
            if (this.state.availableBuses.error) return <ErrorMessage object={this.state.companiesWithBuses} />;
        }
        return (
            <div>
                <BusYieldCalendar availableBuses={this.state.availableBuses} i18n={i18n} t={t}></BusYieldCalendar>
            </div>
        );
    }

    loadBusYieldStatistics() {
        asyncRequest({
            method: 'GET',
            endpoint: `${TOMCAT_URL}api/bus-yield-requests/getYieldByDate`,
        })
            .then(response => {
                this.setState({
                    availableBuses: {
                        entities: response.json,
                        isFetching: false,
                        error: undefined,
                    },
                });
            })
            .catch(error =>
                this.setState({
                    availableBuses: {
                        entities: [],
                        isFetching: false,
                        error: error.status,
                    },
                    filteredCompaniesWithBuses: [],
                }),
            );
    }
}

export default withTranslation()(BusYield);
