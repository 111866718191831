import React from 'react';
import moment from 'moment/moment';
import Toggle from 'material-ui/Toggle';
import { getInterpolatedColorByValue } from 'src/utils/color-interpolation';
import store from 'src/store/store';
import { formatMoney, patch, Projection } from 'src/common/index';
import { countries } from 'src/common/index';

export const validFormatter = (t, isValid, trueLabel, falseLabel) =>
    isValid ? (
        <span title={trueLabel || t('common_phrases.yes')} className="fa fa-check-circle-o text-success fa-lg" />
    ) : (
        <span title={falseLabel || t('common_phrases.no')} className="fa fa-times-circle-o text-danger fa-lg" />
    );

export const yesNoFormatter = (t, value) => (value ? t('common_phrases.yes') : t('common_phrases.no'));

export const apisFormatter = (t, apis, csv = false) => {
    const formattedApis = Object.keys(apis)
        .filter(key => apis[key] && key !== 'hasAny')
        .join(', ');

    if (apis.hasAny) return csv ? formattedApis : <span title={formattedApis}>{formattedApis}</span>;

    return csv ? 'false' : <span title={t('common_phrases.no')} className="fa fa-times-circle-o text-danger fa-lg" />;
};

export const integrationFormatter = (t, integration) =>
    integration ? validFormatter(t, integration, integration.name) : validFormatter(t, integration);

export const percentageFormatter = value => {
    return (
        /* eslint-disable react/jsx-no-literals */
        <span title={`${value.toFixed(2)}%`} style={{ color: getInterpolatedColorByValue(value) }}>
            {value.toFixed(2)} %
        </span>
        /* eslint-enable react/jsx-no-literals */
    );
};

// TODO: BUF-1135: handle format in other languages
export const dateTimeFormatter = date => (date ? `${moment(date).format()} Uhr` : '-');

// TODO: BUF-1135: handle format in other languages
export const dateFormatter = date => (date ? `${moment(date).format('DD.MM.YYYY')}` : '-');

export const priceFormatter = price => formatMoney(price);

export const sumFormatter = price => {
    const color = price >= 0 ? 'green' : 'red';
    return <span style={{ color: color }}>{formatMoney(price)}</span>;
};

export const expiredFormatter = (t, expired) =>
    expired ? (
        <span className="label label-danger">{t('events.expired')}</span>
    ) : (
        <span className="label label-success">{t('events.available')}</span>
    );

export const iconFormatter = (icon, text) => {
    if (text) return <span className={`fa ${icon}`}>{` ${text}`}</span>;
    return <span className={`fa ${icon}`} />;
};

export const toggleFormatter = (row, entity, field, reducerIndex = 0, projection = Projection.FULL) => {
    return (
        <Toggle
            toggled={row[field]}
            onToggle={() =>
                store.dispatch(
                    patch(
                        {
                            [field]: !row[field],
                            id: row.id,
                        },
                        entity,
                        reducerIndex,
                        projection,
                    ),
                )
            }
        />
    );
};

export const countryFormatter = (country, language) => {
    const translatedCountries = language && countries[language] ? countries[language] : countries.en;
    const foundCountry = translatedCountries.find(c => c.value === country);
    return (
        <div>
            {countryFlag(country)}
            &nbsp;{foundCountry ? foundCountry.label : '-'}
        </div>
    );
};

export const countryFlag = country => (
    <span className={`flag-icon flag-icon-${country.toLowerCase()}`} aria-hidden="true" />
);

// TODO: BUF-1135: handle format in other languages
export const periodFormatter = (cell, invoice) => {
    const periodFrom = moment(invoice.periodFrom);
    const periodTill = moment(invoice.periodTill);

    if (periodFrom.isSame(periodTill, 'month')) return periodFrom.format('MMM YYYY');

    return `${periodFrom.format('MMM YYYY')} - ${periodTill.format('MMM YYYY')}`;
};
