import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import { dashIfNull, Entities, getEntities, patch, search, Projection } from 'src/common/index';
import { dateTimeFormatter, priceFormatter } from 'src/utils/formatters';
import { renderCancelTripBookingButton } from 'src/components/trips/calendar/details/BookingDetails';
import {
    getTripBookingFilterState,
    TripBookingFilter,
} from 'src/components/trips/bookings/booking-administration-filter';
import _ from 'lodash';
import { selectEvent } from 'src/actions/events';
import { createBookingEvent } from 'src/selectors/trip-calendar-events/booking-events';
import { withTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ErrorMessage from 'src/components/misc/error-message';

class BookingAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        const queryParam = props.location.query ? props.location.query.filter : TripBookingFilter.FUTURE;

        this.tableHelper = new TableHelper({ onRowClick: this.onRowClick });
        this.state = {
            startedFetching: false,
            filter: getTripBookingFilterState(queryParam.toUpperCase(), props.t),
            bookings: {
                items: [],
                action: 'NONE',
            },
        };
    }

    componentWillMount() {
        this.fetchBookings();
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { bookings, location, t } = nextProps;

        const nextQueryParam = location.query ? location.query.filter : TripBookingFilter.FUTURE;
        const currentQueryParam = this.props.location.query
            ? this.props.location.query.filter
            : TripBookingFilter.FUTURE;

        // if route changed -> set new filter state
        if (nextQueryParam !== currentQueryParam)
            this.setState({
                filter: getTripBookingFilterState(nextQueryParam.toUpperCase(), t),
            });

        // if bookings changed -> filter items and set state with new bookings
        if (!_.isEqual(bookings, this.props.bookings)) {
            // filter and set changed booking items
            const filteredBookings = bookings.items.filter(booking => this.state.filter.condition(booking));
            this.setState({
                bookings: {
                    ...bookings,
                    items: filteredBookings,
                },
            });

            // handle selected booking event that is no longer available
            const selectedRow = this.tableHelper.getRowProps().selected[0];
            if (selectedRow && filteredBookings.findIndex(b => b.id === selectedRow) === -1) {
                this.tableHelper.setSelectedRow();
                nextProps.selectEvent('');
            }
        }

        // determine if fetching started
        if (!this.state.startedFetching && bookings.isFetching) this.setState({ startedFetching: true });

        // process pagination
        this.tableHelper.processPagination(this.state.bookings.items.length);
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedCompany, search } = this.props;

        // if filter changed -> fetch bookings according to new filter
        if (!_.isEqual(this.state.filter, prevState.filter)) this.fetchBookings();

        if (this.tableHelper.processPaginationAfterUpdate(prevState.bookings, this.state.bookings, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { bookings, selectedCompany, t } = this.props;

        const finishFetching = this.state.startedFetching && !bookings.isFetching;

        return (
            <div>
                <legend>{this.state.filter.tableHeader}</legend>

                {(() => {
                    if (bookings.error) return <ErrorMessage object={bookings} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={this.getTableData()}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                {!selectedCompany && (
                                    <TableHeaderColumn columnTitle={true} dataField="company" dataSort={true}>
                                        {t('common_phrases.company')}
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn columnTitle={true} dataField="customer" dataSort={true}>
                                    {t('booked_tasks.customer')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="from"
                                    dataFormat={dateTimeFormatter}
                                    csvFormat={dateTimeFormatter}
                                    dataSort={true}>
                                    {t('booked_tasks.from')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="to"
                                    dataFormat={dateTimeFormatter}
                                    csvFormat={dateTimeFormatter}
                                    dataSort={true}>
                                    {t('booked_tasks.to')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="pax" dataSort={true}>
                                    {t('booked_tasks.persons')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="price"
                                    dataFormat={priceFormatter}
                                    csvFormat={priceFormatter}
                                    dataSort={true}>
                                    {t('booked_tasks.price')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    fetchBookings = () => {
        const { selectedCompany } = this.props;
        this.props.search(
            Entities.TRIP_BOOKING,
            `${this.state.filter.fetchQuery}${selectedCompany ? `?companyId=${selectedCompany}` : ''}`,
            selectedCompany,
            selectedCompany ? Projection.BOOKING : Projection.FULL,
        );
    };

    getTableData = () => {
        const { bookings } = this.state;

        return bookings.items.map(booking => {
            return {
                id: booking.id,
                customer: booking.customerContactData.fullName,
                company: booking.company ? booking.company.companyName : '',
                from: booking.from,
                to: booking.to,
                price: booking.price,
                pax: booking.pax,
                entity: booking,
            };
        });
    };

    tableActionsFormatter(cell, booking) {
        const { selectedCompany, patch, openModal, t } = this.props;

        return <div>{renderCancelTripBookingButton(t, booking.entity, selectedCompany, patch, openModal)}</div>;
    }

    onRowClick = booking => {
        const index = this.state.bookings.items.findIndex(b => b.id === booking.id);

        // booking not available -> unselect event
        if (index === -1) {
            this.tableHelper.setSelectedRow();
            this.props.selectEvent('');

            // select new booking
        } else {
            this.tableHelper.setSelectedRow(booking.id);
            this.props.selectEvent(createBookingEvent(booking.entity));
        }
    };
}

const mapStateToProps = state => {
    const selectedCompany = isNaN(state.selectedCompany) ? 0 : state.selectedCompany;
    return {
        selectedCompany,
        bookings: getEntities(state, Entities.TRIP_BOOKING, selectedCompany),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        search,
        patch,
        selectEvent,
    })(composeModalActions(BookingAdministration)),
);
