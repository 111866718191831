import React, { Component } from 'react';
import { Field } from 'redux-form';
import {
    nullableBooleanMenuItems,
    renderCheckbox,
    renderInput,
    renderSelectField,
} from 'src/components/misc/redux-form-helpers';

class MailConfigForm extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <legend className="legend voffset40">{t('configuration.mail_config')}</legend>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="config.mail-smtp-host"
                            label={`${t('configuration.host')} *`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="config.mail-smtp-port"
                            label={`${t('configuration.port')} *`}
                            component={renderInput}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field name="config.from" label={`${t('configuration.from')} *`} component={renderInput} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="config.username"
                            label={t('configuration.username')}
                            component={renderInput}
                            autocomplete="off"
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="config.password"
                            label={t('configuration.password')}
                            type="password"
                            component={renderInput}
                            autocomplete="off"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 voffset">
                        <Field
                            name="config.mail-smtp-auth"
                            label={t('configuration.authentication')}
                            component={renderSelectField}>
                            {nullableBooleanMenuItems(t)}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="config.mail-smtp-starttls-enable"
                            label={t('configuration.starttls')}
                            component={renderSelectField}>
                            {nullableBooleanMenuItems(t)}
                        </Field>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="config.mail-smtp-ssl-enable"
                            label={t('configuration.ssl')}
                            component={renderSelectField}>
                            {nullableBooleanMenuItems(t)}
                        </Field>
                    </div>
                </div>
            </div>
        );
    }
}

export default MailConfigForm;

export const validateMailServerConfig = (configValues, t) => {
    const configErrors = {};

    if (configValues == null) configValues = {};

    if (!configValues['mail-smtp-host']) configErrors['mail-smtp-host'] = t('configuration.fill_in_host');

    if (!configValues['mail-smtp-port']) configErrors['mail-smtp-port'] = t('configuration.fill_in_port');

    if (!configValues['from']) configErrors['from'] = t('configuration.fill_in_sender');

    return configErrors;
};
