import _ from 'lodash';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { apisFormatter, countryFormatter, validFormatter } from 'src/utils/formatters';
import { FilterToggle } from './filter-toggle';

class CompanyTable extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.selectedRow = [];

        this.state = {
            tableData: this.setTableData(props.companies),
        };
    }

    componentWillReceiveProps(props, state) {
        this.setState({ tableData: this.setTableData(props.companies) });
        if (!props.companySelected) {
            this.selectedRow = [];
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div>
                <BootstrapTable
                    ref={ref => (this.table = ref)}
                    data={this.state.tableData}
                    exportCSV={true}
                    striped={true}
                    hover={true}
                    condensed={true}
                    searchPlaceholder={t('common_phrases.search')}
                    options={{
                        clearSearch: true,
                        onRowClick: this.onSelectRow,
                        toolBar: this.createCustomToolBar,
                    }}
                    selectRow={{
                        mode: 'radio',
                        hideSelectColumn: true,
                        clickToSelect: true,
                        bgColor: 'rgb(173, 216, 230)',
                        selected: this.selectedRow,
                    }}
                    search={true}>
                    <TableHeaderColumn columnTitle={true} dataField="id" hidden={true} isKey={true} export={true}>
                        {t('common_phrases.id')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="companyName" dataSort={true}>
                        {t('common_phrases.company')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="companyLocation" dataSort={true}>
                        {t('bus.headquarters')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        dataField="companyCountry"
                        dataSort={true}
                        dataFormat={cell => countryFormatter(cell, i18n.language)}>
                        {t('contact_data.country')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="onlinePaymentEnabled"
                        width="130"
                        dataAlign="center"
                        dataFormat={cell => validFormatter(t, cell)}
                        dataSort={true}>
                        {t('bus.online_payment')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="apis"
                        width="130"
                        dataAlign="center"
                        dataFormat={(cell, row) => apisFormatter(t, row.apis)}
                        csvFormat={(cell, row) => apisFormatter(t, row.apis, true)}
                        dataSort={true}>
                        {/*TODO: BUF-1135: translate*/}
                        {'APIs'}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="omniPlusON"
                        width="130"
                        dataAlign="center"
                        dataFormat={cell => validFormatter(t, cell)}
                        dataSort={true}>
                        {/*TODO: BUF-1135: translate*/}
                        {'OMNIplus ON'}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="uniqueLocations" width="130" dataSort={true}>
                        {t('bus.bus_locations')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="activeBuses" width="130" dataSort={true}>
                        {t('bus.active_buses')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="inactiveBuses" width="130" dataSort={true}>
                        {t('bus.inactive_buses')}
                    </TableHeaderColumn>
                    <TableHeaderColumn columnTitle={true} dataField="sumBuses" width="130" dataSort={true}>
                        {t('bus.sum_buses')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="percentageActive"
                        dataAlign="right"
                        width="100"
                        dataFormat={this.percentageFormatter}
                        dataSort={true}>
                        {t('bus.percentage_active')}
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    percentageFormatter(value) {
        let color = '#FF652D';

        if (value === 0) color = 'red';
        else if (value === 100) color = 'green';

        return <span style={{ color: color }}>{value.toFixed(2)} %</span>;
    }

    onSelectRow(company) {
        if (company.id !== this.selectedRow[0]) {
            this.selectedRow = [company.id];
            this.props.onSelectCompany(company.id);
        } else {
            this.selectedRow = [];
            this.props.onSelectCompany(undefined);
        }
    }

    setTableData(companies) {
        const { i18n } = this.props;

        return companies.map(company => {
            const activeBuses = company.buses.filter(bus => bus.active);

            // calculate unique bus locations per company
            const uniqueLocations = [];
            activeBuses.forEach(bus => {
                if (uniqueLocations.find(location => _.isEqual(location, bus.homeBase)) === undefined)
                    uniqueLocations.push(bus.homeBase);
            });

            return {
                id: company.id,
                companyName: company.companyName,
                companyLocation: company.location.address,
                companyCountry: company.contactData.country,
                activeBuses: activeBuses.length,
                percentageActive: company.buses.length === 0 ? 0 : (activeBuses.length * 100) / company.buses.length,
                inactiveBuses: company.buses.length - activeBuses.length,
                sumBuses: company.buses.length,
                uniqueLocations: uniqueLocations.length,
                onlinePaymentEnabled: company.onlinePaymentModuleEnabled,
                apis: company.apis,
                hasAnyAPI: company.apis.hasAny,
                omniPlusON: Boolean(company.externalCompanyId),
            };
        });
    }

    createCustomToolBar = toolbarProps => {
        const { onToggle, filters, t } = this.props;

        return (
            <div style={{ display: 'flex', marginTop: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 50 }}>
                    <FilterToggle
                        label={t('bus.active_buses')}
                        labelStyle={{
                            width: '105px',
                        }}
                        toggled={filters.active}
                        onToggle={() => {
                            onToggle('active');
                        }}
                    />
                    <FilterToggle
                        label={t('bus.online_payment')}
                        labelStyle={{
                            width: '120px',
                        }}
                        toggled={filters.onlinePayment}
                        onToggle={() => {
                            onToggle('onlinePayment');
                        }}
                    />
                    <FilterToggle
                        // TODO: BUF-1135: translate
                        label="APIs"
                        toggled={filters.hasAnyAPI}
                        onToggle={() => {
                            onToggle('hasAnyAPI');
                        }}
                    />
                    <FilterToggle
                        // TODO: BUF-1135: translate
                        label="OMNIplus ON"
                        toggled={filters.omniPlusON}
                        labelStyle={{
                            width: '110px',
                        }}
                        onToggle={() => {
                            onToggle('omniPlusON');
                        }}
                    />
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexGrow: 1, gap: 10 }}>
                    <div style={{ width: 400, marginRight: 6 }}>{toolbarProps.components.searchPanel}</div>
                    <div>{toolbarProps.components.exportCSVBtn}</div>
                </div>
            </div>
        );
    };
}

export default CompanyTable;
