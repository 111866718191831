import FormData from 'form-data';
import { CALL_API } from 'redux-api-middleware';
import { TOMCAT_URL, AUTHENTICATION_TOKEN_HEADER, AUTHENTICATION_TOKEN_STORAGE_KEY } from 'src/common/index';

export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_FAILURE = 'DOCUMENTS_FAILURE';

export function upload(files) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    const config = {};
    config.endpoint = `${TOMCAT_URL}api/documents/upload`;
    config.method = 'POST';
    config.credentials = 'include';
    config.body = formData;
    config.headers = {
        [AUTHENTICATION_TOKEN_HEADER]: localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY),
    };

    console.log(config.method, config.endpoint);

    return dispatch => {
        return dispatch({
            [CALL_API]: {
                ...config,
                types: [DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS, DOCUMENTS_FAILURE],
            },
        });
    };
}
