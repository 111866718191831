import { List, ListItem, makeSelectable } from 'material-ui/List';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import { amber700, blue700, green700 } from 'material-ui/styles/colors';
import AccountIcon from 'material-ui/svg-icons/action/account-circle';
import LoadingIcon from 'material-ui/svg-icons/action/autorenew';
import AcceptedIcon from 'material-ui/svg-icons/action/done';
import HomeIcon from 'material-ui/svg-icons/action/home';
import ListIcon from 'material-ui/svg-icons/action/list';
import LabelIcon from 'material-ui/svg-icons/action/label';
import LabelOutlineIcon from 'material-ui/svg-icons/action/label-outline';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import UploadIcon from 'material-ui/svg-icons/file/file-upload';
import ClosedFolderIcon from 'material-ui/svg-icons/file/folder';
import OpenFolderIcon from 'material-ui/svg-icons/file/folder-open';
import PersonIcon from 'material-ui/svg-icons/social/person';
import FilledStarIcon from 'material-ui/svg-icons/toggle/star';
import EmptyStarIcon from 'material-ui/svg-icons/toggle/star-border';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import EuroIcon from 'material-ui/svg-icons/action/euro-symbol';
import BookIcon from 'material-ui/svg-icons/action/book';
import Toggle from 'material-ui/Toggle';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import EventModal from 'src/components/events/event-modal';
import Details from 'src/components/operatingtool/details';
import { EventType } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TaskList from 'src/components/taskadministration/task-list';
import { Entities } from 'src/common/index';
import { withTranslation } from 'react-i18next';

let SelectableList = makeSelectable(List);

export const taskAdministrationFilterMappings = t => ({
    MY_TASKS: {
        filter: 'MY_TASKS',
        title: t('colors.own_tasks'),
    },
    MY_OPEN_TASKS: {
        filter: 'MY_OPEN_TASKS',
        title: t('tasks.open'),
    },
    MY_ASSIGNED_TASKS: {
        filter: 'MY_ASSIGNED_TASKS',
        title: t('tasks.assigned'),
    },
    MY_RELEASED_TASKS: {
        filter: 'MY_RELEASED_TASKS',
        title: t('tasks.released'),
    },
    MY_RELEASED_TASKS_PENDING: {
        filter: 'MY_RELEASED_TASKS_PENDING',
        title: t('tasks.released_pending'),
    },
    MY_RELEASED_TASKS_ACCEPTED: {
        filter: 'MY_RELEASED_TASKS_ACCEPTED',
        title: t('tasks.released_accepted'),
    },
    FOREIGN_RELEASED_TASKS: {
        filter: 'FOREIGN_RELEASED_TASKS',
        title: t('tasks.released_extern'),
    },
    FOREIGN_RELEASED_TASKS_PENDING: {
        filter: 'FOREIGN_RELEASED_TASKS_PENDING',
        title: t('tasks.released_extern_pending'),
    },
    FOREIGN_RELEASED_TASKS_ACCEPTED: {
        filter: 'FOREIGN_RELEASED_TASKS_ACCEPTED',
        title: t('tasks.released_extern_accepted'),
    },
    BOOKED_BY_CUSTOMER: {
        filter: 'BOOKED_BY_CUSTOMER',
        title: t('tasks.booked_main'),
    },
    BOOKED_BY_CUSTOMER_WITH_REVIEW: {
        filter: 'BOOKED_BY_CUSTOMER_WITH_REVIEW',
        title: t('tasks.booked_rating'),
    },
    BOOKED_BY_CUSTOMER_WITHOUT_REVIEW: {
        filter: 'BOOKED_BY_CUSTOMER_WITHOUT_REVIEW',
        title: t('tasks.booked_without_rating'),
    },
    BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS: {
        filter: 'BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS',
        title: t('tasks.sending_due'),
    },
    BOOKED_BY_CUSTOMER_NOT_RECEIVED: {
        filter: 'BOOKED_BY_CUSTOMER_NOT_RECEIVED',
        title: t('tasks.booked_tasks_not_received'),
    },
    BOOKED_BY_CUSTOMER_PENDING_PAYMENT: {
        filter: 'BOOKED_BY_CUSTOMER_PENDING_PAYMENT',
        title: t('tasks.pending_payment'),
    },
    BOOKED_BY_CUSTOMER_ON_INTEGRATION: {
        filter: 'BOOKED_BY_CUSTOMER_ON_INTEGRATION',
        title: t('tasks.booked_on_integration'),
    },
    NOT_CLEARED: {
        filter: 'NOT_CLEARED',
        title: t('tasks.not_calculated'),
    },
    CLEARED: {
        filter: 'CLEARED',
        title: t('tasks.calculated_tasks'),
    },
    TASKS_BY_DRIVERS: {
        filter: 'TASKS_BY_DRIVERS',
        title: t('tasks.by_driver'),
    },
    ALL_TASKS: {
        filter: 'ALL_TASKS',
        title: t('tasks.all'),
    },
});

/**
 * Overall Task-Administration
 * According to the selected filter the route forwards to the appropriate task list
 */
class TaskAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        const query = props.location.query;
        const { t } = props;
        const currentFilterMapping =
            query && query.filter
                ? taskAdministrationFilterMappings(t)[query.filter]
                : taskAdministrationFilterMappings(t).MY_TASKS;

        this.state = {
            filter: currentFilterMapping.filter,
            tableTitle: currentFilterMapping.title,
            showExpiredTasks: false,
            showCancelledTasks: false,
            currentSelectedDriver: null,
        };
    }

    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.setFetchMethodOnError(this.fetchOnError);

        this.props.fetchOwnBusCompany(selectedCompany);
        this.props.fetchTasksByFilter(selectedCompany, false, false, this.state.filter);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    render() {
        const { drivers, selectedCompany, t } = this.props;
        const driverMenuItems = [];

        const filterMapping = taskAdministrationFilterMappings(t);

        // populate driver menu items if they are available
        if (
            this.state.filter === 'TASKS_BY_DRIVERS' &&
            !drivers.isFetching &&
            drivers &&
            drivers.items &&
            drivers.items.length > 0
        ) {
            drivers.items.forEach((driver, index) => {
                driverMenuItems.push(
                    <MenuItem key={index} value={driver.id} primaryText={driver.contactData.firstName} />,
                );
            });
        }

        return (
            <div className="row">
                <div className="col-md-2 sidebar-listview">
                    <div>
                        <Toggle
                            label={t('tasks.past')}
                            toggled={this.state.showExpiredTasks === true}
                            onToggle={() => {
                                this.setState({
                                    showExpiredTasks: !this.state.showExpiredTasks,
                                });

                                if (this.state.filter === 'TASKS_BY_DRIVERS' && this.state.currentSelectedDriver)
                                    this.props.fetchTasksByDriver(
                                        this.state.currentSelectedDriver,
                                        selectedCompany,
                                        !this.state.showExpiredTasks,
                                        this.state.showCancelledTasks,
                                    );
                                else if (this.state.filter !== 'TASKS_BY_DRIVERS')
                                    this.props.fetchTasksByFilter(
                                        selectedCompany,
                                        !this.state.showExpiredTasks,
                                        this.state.showCancelledTasks,
                                        this.state.filter,
                                    );
                            }}
                        />
                    </div>
                    <div>
                        <Toggle
                            label={t('tasks.cancelled')}
                            toggled={this.state.showCancelledTasks === true}
                            onToggle={() => {
                                this.setState({
                                    showCancelledTasks: !this.state.showCancelledTasks,
                                });

                                if (this.state.filter === 'TASKS_BY_DRIVERS' && this.state.currentSelectedDriver)
                                    this.props.fetchTasksByDriver(
                                        this.state.currentSelectedDriver,
                                        selectedCompany,
                                        this.state.showExpiredTasks,
                                        !this.state.showCancelledTasks,
                                    );
                                else if (this.state.filter !== 'TASKS_BY_DRIVERS')
                                    this.props.fetchTasksByFilter(
                                        selectedCompany,
                                        this.state.showExpiredTasks,
                                        !this.state.showCancelledTasks,
                                        this.state.filter,
                                    );
                            }}
                        />
                    </div>
                    <SelectableList value={this.state.filter} onChange={() => this}>
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={0}
                            value={filterMapping.MY_TASKS.filter}
                            primaryText={t('colors.own_tasks')}
                            leftIcon={<HomeIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.MY_TASKS)}
                            nestedItems={[
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={1}
                                    value={filterMapping.MY_OPEN_TASKS.filter}
                                    primaryText={t('offers.open')}
                                    leftIcon={<OpenFolderIcon color={blue700} />}
                                    onClick={() => this.changeFilter(filterMapping.MY_OPEN_TASKS)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={2}
                                    value={filterMapping.MY_ASSIGNED_TASKS.filter}
                                    primaryText={t('offers.assigned')}
                                    leftIcon={<ClosedFolderIcon color={blue700} />}
                                    onClick={() => this.changeFilter(filterMapping.MY_ASSIGNED_TASKS)}
                                />,
                            ]}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={3}
                            value={filterMapping.MY_RELEASED_TASKS.filter}
                            primaryText={t('offers.released')}
                            leftIcon={<UploadIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.MY_RELEASED_TASKS)}
                            nestedItems={[
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={4}
                                    value={filterMapping.MY_RELEASED_TASKS_PENDING.filter}
                                    primaryText={t('offers.outstanding')}
                                    leftIcon={<LoadingIcon color={blue700} />}
                                    onClick={() => this.changeFilter(filterMapping.MY_RELEASED_TASKS_PENDING)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={5}
                                    value={filterMapping.MY_RELEASED_TASKS_ACCEPTED.filter}
                                    primaryText={t('offers.accepted')}
                                    leftIcon={<AcceptedIcon color={green700} />}
                                    onClick={() => this.changeFilter(filterMapping.MY_RELEASED_TASKS_ACCEPTED)}
                                />,
                            ]}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={6}
                            value={filterMapping.FOREIGN_RELEASED_TASKS.filter}
                            primaryText={t('offers.extern')}
                            leftIcon={<DownloadIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.FOREIGN_RELEASED_TASKS)}
                            nestedItems={[
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={7}
                                    value={filterMapping.FOREIGN_RELEASED_TASKS_PENDING.filter}
                                    primaryText={t('offers.outstanding')}
                                    leftIcon={<LoadingIcon color={blue700} />}
                                    onClick={() => this.changeFilter(filterMapping.FOREIGN_RELEASED_TASKS_PENDING)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={8}
                                    value={filterMapping.FOREIGN_RELEASED_TASKS_ACCEPTED.filter}
                                    primaryText={t('offers.accepted')}
                                    leftIcon={<AcceptedIcon color={green700} />}
                                    onClick={() => this.changeFilter(filterMapping.FOREIGN_RELEASED_TASKS_ACCEPTED)}
                                />,
                            ]}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={9}
                            value={filterMapping.BOOKED_BY_CUSTOMER.filter}
                            primaryText={t('offers.booked')}
                            leftIcon={<AccountIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER)}
                            nestedItems={[
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={10}
                                    value={filterMapping.BOOKED_BY_CUSTOMER_WITH_REVIEW.filter}
                                    primaryText={t('booked_tasks.with_rating')}
                                    leftIcon={<FilledStarIcon color={amber700} />}
                                    onClick={() => this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER_WITH_REVIEW)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={11}
                                    value={filterMapping.BOOKED_BY_CUSTOMER_WITHOUT_REVIEW.filter}
                                    primaryText={t('booked_tasks.without_rating')}
                                    leftIcon={<EmptyStarIcon color={amber700} />}
                                    onClick={() => this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER_WITHOUT_REVIEW)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={12}
                                    value={filterMapping.BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS.filter}
                                    primaryText={t('tasks.reminder_data')}
                                    leftIcon={<EmailIcon color={amber700} />}
                                    onClick={() =>
                                        this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS)
                                    }
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={13}
                                    value={filterMapping.BOOKED_BY_CUSTOMER_NOT_RECEIVED.filter}
                                    primaryText={t('booked_tasks.not_yet_received')}
                                    leftIcon={<LoadingIcon color={amber700} />}
                                    onClick={() => this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER_NOT_RECEIVED)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={14}
                                    value={filterMapping.BOOKED_BY_CUSTOMER_PENDING_PAYMENT.filter}
                                    primaryText={t('tasks.pending_payment')}
                                    leftIcon={<EuroIcon color={amber700} />}
                                    onClick={() => this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER_PENDING_PAYMENT)}
                                />,
                                <ListItem
                                    style={{ fontSize: '14px' }}
                                    key={15}
                                    value={filterMapping.BOOKED_BY_CUSTOMER_ON_INTEGRATION.filter}
                                    primaryText={filterMapping.BOOKED_BY_CUSTOMER_ON_INTEGRATION.title}
                                    leftIcon={<BookIcon color={amber700} />}
                                    onClick={() => this.changeFilter(filterMapping.BOOKED_BY_CUSTOMER_ON_INTEGRATION)}
                                />,
                            ]}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={12}
                            value={filterMapping.NOT_CLEARED.filter}
                            primaryText={t('tasks.not_calculated')}
                            leftIcon={<LabelOutlineIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.NOT_CLEARED)}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={12}
                            value={filterMapping.CLEARED.filter}
                            primaryText={t('tasks.calculated')}
                            leftIcon={<LabelIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.CLEARED)}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={13}
                            value={filterMapping.TASKS_BY_DRIVERS.filter}
                            primaryText={t('tasks.by_driver')}
                            leftIcon={<PersonIcon color={blue700} />}
                            onClick={() => {
                                this.props.fetchByCompanyId(Entities.DRIVER, selectedCompany);

                                if (this.state.currentSelectedDriver)
                                    this.props.fetchTasksByDriver(
                                        this.state.currentSelectedDriver,
                                        selectedCompany,
                                        this.state.showExpiredTasks,
                                        this.state.showCancelledTasks,
                                    );

                                this.setState({
                                    filter: filterMapping.TASKS_BY_DRIVERS.filter,
                                    tableTitle: filterMapping.TASKS_BY_DRIVERS.title,
                                });
                            }}
                        />
                        <ListItem
                            style={{ fontSize: '14px' }}
                            key={12}
                            value={filterMapping.ALL_TASKS.filter}
                            primaryText={t('common_phrases.all')}
                            leftIcon={<ListIcon color={blue700} />}
                            onClick={() => this.changeFilter(filterMapping.ALL_TASKS)}
                        />
                    </SelectableList>
                </div>

                <div id="tasklist-panel" className="col-md-10">
                    <legend className="legend">
                        {this.state.tableTitle}
                        <button
                            className="btn btn-xs btn-link nav-link pull-right"
                            onClick={() => {
                                this.createEventModal({
                                    eventType: EventType.TASK,
                                    start: moment().startOf('day'),
                                    end: moment().startOf('day').hour(23).minute(59),
                                    title: t('events.new_task'),
                                    t,
                                });
                            }}>
                            <span className="glyphicon glyphicon-plus" />
                            &nbsp;{t('events.new_task')}
                        </button>
                    </legend>

                    {this.state.filter === 'TASKS_BY_DRIVERS' && (
                        <div className="row">
                            <div className="col-md-4 col-md-offset-8">
                                <SelectField
                                    value={this.state.currentSelectedDriver}
                                    onChange={(event, index, value) => {
                                        this.setState({ currentSelectedDriver: value });
                                        this.props.fetchTasksByDriver(
                                            value,
                                            selectedCompany,
                                            this.state.showExpiredTasks,
                                            this.state.showCancelledTasks,
                                        );
                                    }}
                                    floatingLabelText={t('administration.driver')}
                                    hintText={t('tasks.select_driver')}
                                    fullWidth={true}
                                    floatingLabelFixed={true}>
                                    {driverMenuItems}
                                </SelectField>
                            </div>
                        </div>
                    )}

                    <TaskList
                        t={t}
                        showExpiredTasks={this.state.showExpiredTasks}
                        showCancelledTasks={this.state.showCancelledTasks}
                        taskFilter={this.state.filter}
                        receivedTask={this.props.location.query && this.props.location.query.receivedTask}
                        busCompany={this.props.busCompany}
                        currentSelectedDriver={this.state.currentSelectedDriver}
                        buses={this.props.buses}
                        selectedCompany={this.props.selectedCompany}
                        tasks={this.props.tasks}
                        reviews={this.props.reviews}
                        drivers={this.props.drivers}
                        patch={this.props.patch}
                        deleteById={this.props.deleteById}
                        selectEvent={this.props.selectEvent}
                        addNotification={this.props.addNotification}
                        duplicateTask={this.props.duplicateTask}
                        openModal={this.props.openModal}
                    />

                    <div className="row">
                        <div className="col-md-12 detailscontainer">
                            <Details
                                eventDetails={this.props.eventDetails}
                                busCompany={this.props.busCompany}
                                deleteById={this.props.deleteById}
                                patch={this.props.patch}
                                addNotification={this.props.addNotification}
                                openModal={this.props.openModal}
                                duplicateTask={this.props.duplicateTask}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeFilter(changedFilterMapping) {
        const { selectedCompany } = this.props;

        this.props.push(`/task-administration?filter=${changedFilterMapping.filter}`);
        this.props.fetchTasksByFilter(
            selectedCompany,
            this.state.showExpiredTasks,
            this.state.showCancelledTasks,
            changedFilterMapping.filter,
        );
        this.setState({
            filter: changedFilterMapping.filter,
            tableTitle: changedFilterMapping.title,
        });
    }

    fetchOnError() {
        this.props.fetchTasksByFilter(
            this.props.selectedCompany,
            this.state.showExpiredTasks,
            this.state.showCancelledTasks,
            this.state.filter,
        );
    }

    createEventModal(props) {
        //TODO: before create, we should reset the table filter this.table.reset() (but there is no ref here)
        this.props.openModal({
            component: EventModal,
            componentProps: {
                eventType: props.eventType,
                isCreate: true,
                initialValuesOnCreate: {
                    start: props.start,
                    end: props.end,
                    fromLocation: this.props.busCompany.entity.location,
                    name: props.t('events.task'),
                },
            },
            title: props.title,
            mandatoryFields: true,
        });
    }
}

export default withTranslation()(composeModalActions(TaskAdministration));
