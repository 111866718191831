import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Entities,
    fetchByCompanyId,
    fetchById,
    getEntities,
    getUpdatedEntity,
    save,
    patch,
    Projection,
} from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import CustomerDiscountGroupEditForm from 'src/components/administration/customer-discount-group-edit-form';
import autoBind from 'react-autobind';

class CustomerDiscountGroupEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, fetchById, fetchByCompanyId, selectedCompany } = this.props;

        fetchByCompanyId(Entities.DISCOUNT_GROUP, selectedCompany, Projection.FULL);
        fetchById(id, Entities.CUSTOMER, selectedCompany, Projection.FULL);
    }

    componentWillReceiveProps(nextProps) {
        const { customer } = nextProps;

        if (!this.state.startedFetching && customer.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { discountGroups, customer, selectedCompany, isImportRatioCustomerModuleEnabled, t } = this.props;
        const finishFetching = this.state.startedFetching && !customer.isFetching && !discountGroups.isFetching;

        if (finishFetching) customer.content.ratioCustomerId = this.props.ratioCustomerId;
        if (finishFetching) {
            // check for errors
            if (customer.error) return <ErrorMessage object={customer} />;
            // if everything is ok populate form
            else {
                let initialValues = {};

                if (customer.content)
                    initialValues = {
                        id: customer.content.id,
                        email: customer.content.email,
                        ratioCustomerId: this.props.ratioCustomerId,
                        discountGroupId: customer.content.discountGroup ? customer.content.discountGroup.id : undefined,
                        companyId: selectedCompany,
                        transferNumber: customer.content.transferNumber,
                    };

                return (
                    <CustomerDiscountGroupEditForm
                        t={t}
                        initialValues={initialValues}
                        isImportRatioCustomerModuleEnabled={isImportRatioCustomerModuleEnabled}
                        onSubmit={this.handleSubmit}
                        ref={ref => (this.childForm = ref)}
                        discountGroups={discountGroups.items}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, patch, selectedCompany } = this.props;

        patch(data, Entities.CUSTOMER, selectedCompany, Projection.FULL, undefined, 'update-discount-group');

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        discountGroups: getEntities(state, Entities.DISCOUNT_GROUP, state.selectedCompany),
        customer: getUpdatedEntity(state, Entities.CUSTOMER, state.selectedCompany),
        selectedCompany: state.selectedCompany,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchByCompanyId,
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(CustomerDiscountGroupEditModal);
