import { connect } from 'react-redux';
import { addNotification, addErrorNotification } from 'src/actions/notifications';
import { Entities, fetchAll, getEntities, patch } from 'src/common/index';
import AdminInvoiceAdministration from 'src/components/admin/invoices/admin-invoice-administration';

const mapStateToProps = state => {
    return {
        invoices: getEntities(state, Entities.INVOICE),
    };
};

const AdminInvoiceAdministrationContainer = connect(mapStateToProps, {
    fetchAll,
    patch,
    addNotification,
    addErrorNotification,
})(AdminInvoiceAdministration);

export default AdminInvoiceAdministrationContainer;
