import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { logout, UserRole } from 'src/common/index';
import { isLoggedInWithRole } from 'src/components/account/login';
import { getOwnAccount } from 'src/selectors/accounts';
import { getOwnCompany } from 'src/selectors/bus-companies';

class Home extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillMount() {
        this.redirect(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.redirect(nextProps);
    }

    redirect(props) {
        const { ownAccount, busCompany, logout, push } = props;

        if (isLoggedInWithRole(ownAccount, [UserRole.ADMIN, UserRole.SUPER_ADMIN])) push('/admin');
        else if (isLoggedInWithRole(ownAccount, [UserRole.OPERATOR, UserRole.DISPATCHER])) {
            if (busCompany.entity.partner) push('/operating-tool');
            else push('/event-tickets');
        } else if (isLoggedInWithRole(ownAccount, [UserRole.TRIP_OPERATOR])) push('/trips/calendar');
        else if (isLoggedInWithRole(ownAccount, [UserRole.CUSTOMER, UserRole.DRIVER])) {
            console.log('Customer or driver logged in on operating tool.');
            logout();
        }
    }

    render() {
        return <CircularProgress />;
    }
}

const mapStateToProps = state => {
    return {
        ownAccount: getOwnAccount(state),
        busCompany: getOwnCompany(state),
    };
};

export default connect(mapStateToProps, { push, logout })(Home);
