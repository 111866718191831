import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import TextField from 'material-ui/TextField';
import { asyncCheckDuplicate, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank, isEmailInvalid } from 'src/components/misc/validations';
import { isFinite } from 'lodash';

class SchoolEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { handleSubmit, currentActiveBusPoints, school, isCreate, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    {!isCreate && (
                        <div className="col-md-6">
                            <TextField
                                type="text"
                                value={currentActiveBusPoints + school.nonActiveBusPoints}
                                fullWidth={true}
                                disabled={true}
                                title={t('schools.points_availability')}
                                floatingLabelText={t('schools.sum_points')}
                            />
                        </div>
                    )}
                    <div className="col-md-6">
                        <Field
                            name="busPoints"
                            label={`${t('schools.available_points')} *`}
                            type="number"
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="schoolName" label={`${t('schools.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field name="type" label={`${t('schools.type')}`} component={renderInput} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="contactData.email" label={`${t('schools.email')}`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field name="contactData.phone" label={`${t('schools.telephone')}`} component={renderInput} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field
                            name="contactData.street"
                            label={`${t('contact_data.street')}`}
                            component={renderInput}
                        />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="contactData.postCode"
                            label={`${t('contact_data.post_code')}`}
                            component={renderInput}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="contactData.city" label={`${t('contact_data.city')}`} component={renderInput} />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {
        contactData: {},
    };

    if (values.busPoints == null) errors.busPoints = t('schools.fill_in_bus_points');
    else if (values.busPoints < 0) errors.busPoints = t('schools.error_negative_points');

    if (isBlank(values.schoolName)) errors.schoolName = t('schools.fill_in_name');
    if (values.contactData) {
        if (isEmailInvalid(values.contactData.email) && !isBlank(values.contactData.email))
            errors.contactData.email = t('error_hint.error_invalid_email');
    }

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.SCHOOL.repository}/search/findBySchoolName?schoolName=${encodeURIComponent(
            values.schoolName,
        )}`,
        values.id,
        'schoolName',
    );
};

SchoolEditForm = reduxForm({
    form: 'schoolEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['schoolName'],
})(SchoolEditForm);

const selector = formValueSelector('schoolEditForm');

const mapStateToProps = (state, ownProps) => {
    const { school, isCreate } = ownProps;

    const currentActiveBusPoints = Number(selector(state, 'busPoints'));

    let initialValues = {};

    if (!isCreate && school) {
        initialValues = {
            id: school.id,
            busPoints: school.busPoints,
            schoolName: school.schoolName,
            type: school.type,
            contactData: {
                email: school.contactData.email,
                phone: school.contactData.phone,
                street: school.contactData.street,
                postCode: school.contactData.postCode,
                city: school.contactData.city,
            },
        };
    }

    return {
        initialValues,
        currentActiveBusPoints,
    };
};

SchoolEditForm = connect(mapStateToProps, null, null, { withRef: true })(SchoolEditForm);

export default SchoolEditForm;
