import { OfferStatus } from 'src/utils/constants';
import { Entities, Projection, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';

export const REQUEST_TASKS = 'REQUEST_TASKS';
export const SUCCESS_FETCH_TASKS = 'SUCCESS_FETCH_TASKS';
export const SUCCESS_SAVE_TASKS = 'SUCCESS_SAVE_TASKS';
export const SUCCESS_UPDATE_TASKS = 'SUCCESS_UPDATE_TASKS';
export const SUCCESS_DELETE_TASKS = 'SUCCESS_DELETE_TASKS';
export const FAILURE_TASKS = 'FAILURE_TASKS';

export const FETCH_METHOD = 'FETCH_METHOD';

export const NEW_TASKS_REDUCER_INDEX = 'new';

export function fetchTasksByCompany(busCompany, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TASK.repository
                }/search/findTasksByMonth?companyId=${busCompany}${projection ? `&projection=${projection}` : ''}`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                reducerIndex: busCompany,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

export function fetchTasksByFilter(busCompany, pastTasks, cancelledTasks, filter, projection = Projection.DEFAULT) {
    let fetchMethod = 'findOwnTasks';

    switch (filter) {
        case 'MY_TASKS':
            fetchMethod = 'findOwnTasks';
            break;
        case 'MY_OPEN_TASKS':
            fetchMethod = 'findOwnOpenTasks';
            break;
        case 'MY_ASSIGNED_TASKS':
            fetchMethod = 'findOwnAssignedTasks';
            break;
        case 'MY_RELEASED_TASKS':
            fetchMethod = 'findTasksReleasedByOwnCompany';
            break;
        case 'MY_RELEASED_TASKS_PENDING':
            fetchMethod = 'findPendingTasksReleasedByOwnCompany';
            break;
        case 'MY_RELEASED_TASKS_ACCEPTED':
            fetchMethod = 'findAcceptedTasksReleasedByOwnCompany';
            break;
        case 'FOREIGN_RELEASED_TASKS':
            fetchMethod = 'findTasksReleasedByExternCompany';
            break;
        case 'FOREIGN_RELEASED_TASKS_PENDING':
            fetchMethod = 'findPendingTasksReleasedByExternCompany';
            break;
        case 'FOREIGN_RELEASED_TASKS_ACCEPTED':
            fetchMethod = 'findAcceptedTasksReleasedByExternCompany';
            break;
        case 'BOOKED_BY_CUSTOMER':
            fetchMethod = 'findTasksBookedForOwnCompany';
            break;
        case 'BOOKED_BY_CUSTOMER_WITH_REVIEW':
            fetchMethod = 'findTasksBookedForOwnCompanyWithReview';
            break;
        case 'BOOKED_BY_CUSTOMER_WITHOUT_REVIEW':
            fetchMethod = 'findTasksBookedForOwnCompanyWithoutReview';
            break;
        case 'BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS':
            fetchMethod = 'findTasksBookedForOwnCompany';
            break;
        case 'BOOKED_BY_CUSTOMER_NOT_RECEIVED':
            fetchMethod = 'findTasksBookedForOwnCompanyNotReceived';
            break;
        case 'BOOKED_BY_CUSTOMER_PENDING_PAYMENT':
            fetchMethod = 'findTasksBookedForOwnCompanyPaymentPending';
            break;
        case 'BOOKED_BY_CUSTOMER_ON_INTEGRATION':
            fetchMethod = 'findTasksBookedForOwnCompanyOnIntegration';
            break;
        case 'OPEN_OFFER':
            fetchMethod = 'findOffersByCompany';
            break;
        case 'NOT_CLEARED':
            fetchMethod = 'findNotClearedTasks';
            break;
        case 'CLEARED':
            fetchMethod = 'findClearedTasks';
            break;
        default:
            fetchMethod = 'findAllCompanyRelatedTasks';
    }

    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TASK.repository
                }/search/${fetchMethod}?companyId=${busCompany}&pastTasks=${pastTasks}&cancelledTasks=${cancelledTasks}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                reducerIndex: busCompany,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

export function fetchTasksByDriver(driverId, busCompany, pastTasks, cancelledTasks, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TASK.repository
                }/search/findByDriverId?driverId=${driverId}&pastTasks=${pastTasks}&cancelledTasks=${cancelledTasks}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                reducerIndex: busCompany,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

export function fetchNewTasksSinceLastLogin(busCompany, previousLogin, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TASK.repository
                }/search/getNewTasksSinceLastLogin?companyId=${busCompany}&previousLogin=${previousLogin}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                reducerIndex: NEW_TASKS_REDUCER_INDEX,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

export function duplicateTask(taskId, busCompany, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/duplicateTask?taskId=${taskId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'POST',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_SAVE_TASKS, FAILURE_TASKS],
                schema: Entities.TASK.schema,
                reducerIndex: busCompany,
            }),
        );
    };
}

export function setFetchMethodOnError(fetchMethod) {
    return {
        type: FETCH_METHOD,
        fetchMethod,
    };
}

// task exchange actions

export function releaseTask(taskExchange, busCompany, successCallback, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/releaseTask${
                    projection ? `?projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompany,
                schema: Entities.TASK.schema,
                body: JSON.stringify(taskExchange),
                successCallback: successCallback,
            }),
        );
    };
}

export function removeTaskFromExchange(
    taskId,
    busCompany,
    errorCallback,
    successCallback,
    projection = Projection.DEFAULT,
) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/removeTaskFromExchange?taskId=${taskId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompany,
                schema: Entities.TASK.schema,
                errorCallback: errorCallback,
                successCallback: successCallback,
            }),
        );
    };
}

export function acceptExchangedTask(
    taskId,
    foreignCompanyId,
    busCompanyId,
    errorCallback,
    successCallback,
    projection = Projection.DEFAULT,
) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TASK.repository
                }/acceptExchangedTask?taskId=${taskId}&foreignCompanyId=${foreignCompanyId}&ownCompanyId=${busCompanyId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompanyId,
                schema: Entities.TASK.schema,
                parameter: taskId,
                errorCallback: errorCallback,
                successCallback: successCallback,
            }),
        );
    };
}

export function declineExchangedTask(taskId, busCompanyId, successCallback, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.TASK.repository
                }/declineExchangedTask?taskId=${taskId}&foreignCompanyId=${busCompanyId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'DELETE',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_DELETE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompanyId,
                schema: Entities.TASK.schema,
                parameter: taskId,
                successCallback: successCallback,
            }),
        );
    };
}

export function disposeTaskExtern(taskId, busCompanyId, projection = 'full') {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/disposeTaskExtern?taskId=${taskId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompanyId,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

export function cancelDisposeExternTask(taskId, busCompanyId, projection = Projection.FULL) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/cancelDisposeExternTask?taskId=${taskId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompanyId,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

// admin actions

export function fetchBookedTasks(filter) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/findAllBookedTasks?filter=${filter}`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                reducerIndex: 0,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

// offers

export function fetchOffersByFilter(busCompany, pastOffers, filter, projection = Projection.DEFAULT) {
    let status;

    switch (filter) {
        case 'OPEN_OFFER':
            status = OfferStatus.OPEN;
            break;
        case 'ACCEPTED_OFFER':
            status = OfferStatus.ACCEPTED;
            break;
        case 'DECLINED_OFFER':
            status = OfferStatus.DECLINED;
            break;
        case 'DELETED_OFFER':
            status = OfferStatus.DELETED;
            break;
        default:
            status = null;
    }

    if (status)
        return dispatch => {
            return dispatch(
                apiRequest({
                    endpoint: `${TOMCAT_URL}api/${
                        Entities.TASK.repository
                    }/search/findOffersByStatusAndCompany?companyId=${busCompany}&pastOffers=${pastOffers}&status=${status}${
                        projection ? `&projection=${projection}` : ''
                    }`,
                    method: 'GET',
                    secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                    reducerIndex: busCompany,
                    schema: Entities.TASK.schema,
                }),
            );
        };
    else
        return dispatch => {
            return dispatch(
                apiRequest({
                    endpoint: `${TOMCAT_URL}api/${
                        Entities.TASK.repository
                    }/search/findAllOffersByCompany?companyId=${busCompany}&pastOffers=${pastOffers}${
                        projection ? `&projection=${projection}` : ''
                    }`,
                    method: 'GET',
                    secondaryActionTypes: [REQUEST_TASKS, SUCCESS_FETCH_TASKS, FAILURE_TASKS],
                    reducerIndex: busCompany,
                    schema: Entities.TASK.schema,
                }),
            );
        };
}

export function deleteReview(taskId, reducerIndex = 0, projection = Projection.FULL) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/deleteReview?taskId=${taskId}${
                    projection ? `&projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: reducerIndex,
                schema: Entities.TASK.schema,
            }),
        );
    };
}

export function sendDriverDetailsToCustomer(
    taskId,
    body,
    busCompanyId,
    errorCallback,
    successCallback,
    projection = Projection.DEFAULT,
) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.TASK.repository}/${taskId}/sendDriverDetailsToCustomer${
                    projection ? `?projection=${projection}` : ''
                }`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                reducerIndex: busCompanyId,
                schema: Entities.TASK.schema,
                body: JSON.stringify(body),
                errorCallback: errorCallback,
                successCallback: successCallback,
            }),
        );
    };
}

export function acceptOffer(bookingToken, busCompanyId, successCallback) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}public/acceptOperatorOffer?token=${bookingToken}`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                schema: Entities.TASK.schema,
                reducerIndex: busCompanyId,
                successCallback,
            }),
        );
    };
}

export function declineOffer(bookingToken, busCompanyId, successCallback) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}public/declineOperatorOffer?token=${bookingToken}`,
                method: 'POST',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                schema: Entities.TASK.schema,
                reducerIndex: busCompanyId,
                successCallback,
            }),
        );
    };
}

export function deleteOffer(bookingToken, busCompanyId, successCallback) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}public/deleteOperatorOffer?token=${bookingToken}`,
                method: 'PATCH',
                secondaryActionTypes: [REQUEST_TASKS, SUCCESS_UPDATE_TASKS, FAILURE_TASKS],
                schema: Entities.TASK.schema,
                reducerIndex: busCompanyId,
                successCallback,
            }),
        );
    };
}
