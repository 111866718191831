import { TOMCAT_URL } from 'src/common/index';
import MenuItem from 'material-ui/MenuItem';
import moment from 'moment';
import React from 'react';
import placeHolder21x9 from 'static/placeholder-350x150.png';

export function scrollToAnchor(anchor) {
    const url = location.href; //Save down the URL without hash.
    location.href = '#' + anchor; //Go to the target element.
    history.replaceState(null, '', url); //Don't like hashes. Changing it back.
}

/**
 * Generates an array of MaterialUI MenuItems from the given values
 * @param values array of items, which are URLs that the backend will convert to entities. For custom endpoints where you need other values use {@link getMenuItemsByCustomValue}
 * @param label property which should represent the label of the MenuItem
 * @returns {Array}
 */
export function getMenuItemsByArray(values, label) {
    const items = [];
    values.forEach((value, index) => {
        items.push(<MenuItem key={index} value={value['_links']['self']['href']} primaryText={value[label]} />);
    });

    return items;
}

/**
 * Generates an array of MaterialUI MenuItems from the given values
 * @param values array of items,
 * @param valueKey used to determine the value of the MenuItem,
 * @param label property which should represent the label of the MenuItem
 * @returns {Array}
 */
export const getMenuItemsByCustomValue = (values, valueKey, label) =>
    values.map((value, index) => <MenuItem key={index} value={value[valueKey]} primaryText={value[label]} />);

export const getMenuItemsByEnum = (values, disabledValues) =>
    values.map((value, index) => (
        <MenuItem
            key={index}
            value={value}
            primaryText={value}
            disabled={disabledValues != null && disabledValues.indexOf(value) > -1}
        />
    ));

export const createMonthMenuItems = (_firstMonth, _valueFormat) => {
    const firstMonth = _firstMonth ? _firstMonth : moment('1.10.2017', 'DD.MM.YYYY');
    const months = moment.duration(moment().diff(firstMonth)).asMonths();

    const monthMenuItems = [];

    for (let i = 0; i < months; i++) {
        const currentMonth = firstMonth.clone().add(i, 'month');

        monthMenuItems.push(
            <MenuItem
                key={i}
                value={_valueFormat !== undefined ? currentMonth.format(_valueFormat) : currentMonth.format('X')}
                primaryText={currentMonth.format('MMMM YYYY')}
            />,
        );
    }

    return monthMenuItems.reverse();
};

export const get21x9Image = document => {
    if (document && document.filename) return `${TOMCAT_URL}public/documents/${document.filename}`;

    return placeHolder21x9;
};

export const isNullOrUndefined = value => value === undefined || value === null;

export const copyToClipboard = (t, value, addNotification) => {
    navigator.clipboard
        .writeText(value)
        .then(r => {
            if (addNotification)
                addNotification(
                    t('common_phrases.success'),
                    t('notifications.clipboard_copy_success'),
                    'success',
                    'tr',
                );
        })
        .catch(e => {
            if (addNotification)
                addNotification(t('common_phrases.error'), t('notifications.clipboard_copy_error'), 'error', 'tr');
        });
};

export const checkIfSold = bus => {
    return bus !== undefined && bus.vin && bus.vin.toUpperCase().includes('SOLD');
};

export const emptyRichText = '<p><br /></p>';
