import GoogleMapsLoader from 'google-maps';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import HttpStatus from 'http-status-codes';
import { asyncRequest, fetchByCompanyId, TOMCAT_URL, UserRole } from 'src/common/index';
import { fetchOwnAccount } from 'src/actions/accounts';
import { fetchNewTasksSinceLastLogin } from 'src/actions/tasks';
import Notifications from 'src/components/operatingtool/notifications';
import { getOwnAccount, isLoggedInAsEvoAdmin } from 'src/selectors/accounts';
import { getOwnCompany } from 'src/selectors/bus-companies';
import { getTaskNewsSinceLastLogin } from 'src/selectors/tasks';
import Footer from 'src/components/nav/footer';
import Header from 'src/components/nav/header';
import FaviconLoader from 'src/components/misc/FaviconLoader';
import { isLoggedInWithRole } from 'src/components/account/login';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Cookies from 'js-cookie';
import i18next, { LOCALE_COOKIE } from 'src/i18n';

const muiTheme = getMuiTheme({
    palette: {
        primary1Color: '#0c66ab',
        accent1Color: 'darkgrey',
    },
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiError: undefined,
        };
    }

    getChildContext() {
        return { muiTheme: getMuiTheme(muiTheme) };
    }

    componentWillMount() {
        // get api version, show an error when unavailable
        this.getApiVersion();

        // check if already logged in and fetch account
        // this also triggers the auto-login on localhost if not already logged in
        this.props.fetchOwnAccount();

        this.redirect(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const { ownAccount } = nextProps;
        let previousLanguage = this.props.ownAccount.entity ? this.props.ownAccount.entity.language : undefined;
        let currentLanguage = ownAccount.entity ? ownAccount.entity.language : undefined;

        if (currentLanguage !== previousLanguage) {
            if (Cookies.get(LOCALE_COOKIE) === 'cimode') i18next.changeLanguage('cimode');
            else i18next.changeLanguage(currentLanguage);
        }

        if (!this.props.ownAccount.entity && ownAccount.entity) {
            // load google api
            GoogleMapsLoader.KEY = Config.GOOGLE_API_KEY;
            GoogleMapsLoader.LIBRARIES = ['places', 'geometry', 'drawing'];
            GoogleMapsLoader.LANGUAGE =
                ownAccount.entity.language && isLoggedInWithRole(ownAccount, UserRole.OPERATOR)
                    ? ownAccount.entity.language
                    : 'de';
            GoogleMapsLoader.REGION =
                ownAccount.entity.contactData.country && isLoggedInWithRole(ownAccount, UserRole.OPERATOR)
                    ? ownAccount.entity.contactData.country
                    : 'AT';
            GoogleMapsLoader.load(google => {
                console.log('google', google);
            });
        }

        this.redirect(nextProps);
    }

    render() {
        const { t, ownAccount, busCompany, children } = this.props;

        if (this.state.apiError) {
            return <div className="alert alert-danger">{t('error_state')}</div>;
        }

        const currentPath = children.props.location.pathname;
        const notFound = children.props.route.path === '*';
        return (
            <div className="container-fluid">
                <Helmet title={`${t('title')} – ${t('header_operating_tool')}`} />
                <FaviconLoader />
                <Notifications />
                <Header
                    ownAccount={ownAccount}
                    busCompany={busCompany}
                    fetchByCompanyId={this.props.fetchByCompanyId}
                    fetchNewTasksSinceLastLogin={this.props.fetchNewTasksSinceLastLogin}
                    newTasksSinceLastLogin={this.props.newTasksSinceLastLogin}
                    notFound={notFound}
                />
                <br />
                {
                    //only load children if ownAccount has been successfully loaded
                    (currentPath === '/login' ||
                        currentPath === '/login-with-token' ||
                        currentPath === '/' ||
                        notFound ||
                        (ownAccount.entity && !ownAccount.isFetching)) &&
                        children
                }
                <Footer />
            </div>
        );
    }

    getApiVersion() {
        asyncRequest(`${TOMCAT_URL}public/version`)
            .then(response => {
                Config.API_VERSION = response.json.version;
                this.setState({ apiError: undefined });
            })
            .catch(error => this.setState({ apiError: error }));
    }

    redirect(props) {
        const { ownAccount, busCompany, push, children } = props;

        const currentPath = children.props.location.pathname;

        if (!ownAccount.isFetching && ownAccount.action.type !== 'NONE') {
            if (
                currentPath !== '/login' &&
                currentPath !== '/login-with-token' &&
                !ownAccount.isFetching &&
                ((ownAccount.error && ownAccount.error.status === HttpStatus.UNAUTHORIZED) ||
                    !ownAccount.entity ||
                    isLoggedInWithRole(ownAccount, [UserRole.CUSTOMER, UserRole.DRIVER]))
            ) {
                Cookies.set('redirectURLAfterLogin', `${location.pathname}${location.search}`, {
                    secure: true,
                    sameSite: 'none',
                });
                push('/login');
            } else if (
                currentPath.startsWith('/admin/') &&
                !isLoggedInWithRole(ownAccount, [UserRole.SUPER_ADMIN, UserRole.ADMIN])
            )
                push('/');
            else if (currentPath.startsWith('/superadmin')) {
                if (isLoggedInAsEvoAdmin()) push('/admin');
                else if (!isLoggedInWithRole(ownAccount, [UserRole.SUPER_ADMIN])) push('/');
            } else if (
                currentPath.startsWith('/administration/') &&
                !isLoggedInWithRole(ownAccount, [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.OPERATOR])
            )
                push('/');
            else if (
                busCompany.entity &&
                !busCompany.entity.partner &&
                this.getOperatorDispatcherPartnerPaths().some(path => currentPath.startsWith(path))
            )
                push('/');
        }
    }

    getOperatorDispatcherPartnerPaths() {
        return [
            '/operating-tool',
            '/administration',
            '/task-administration',
            '/task-exchange',
            '/offer-administration',
            '/help',
        ];
    }
}

App.propTypes = {
    children: PropTypes.element,
};

App.childContextTypes = {
    muiTheme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        ownAccount: getOwnAccount(state),
        busCompany: getOwnCompany(state),
        newTasksSinceLastLogin: getTaskNewsSinceLastLogin(state),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        push,
        fetchOwnAccount,
        fetchByCompanyId,
        fetchNewTasksSinceLastLogin: fetchNewTasksSinceLastLogin,
    })(DragDropContext(HTML5Backend)(App)),
);
