import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { asyncCheckDuplicate, renderInput, renderSelectField } from 'src/components/misc/redux-form-helpers';
import _ from 'lodash';
import { CustomerGroupType } from 'src/utils/constants';
import MenuItem from 'material-ui/MenuItem';
import { Entities, TOMCAT_URL } from 'src/common/index';
import { isBlank } from 'src/components/misc/validations';

class AdminCustomerGroupEditForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                    <div className="col-md-6">
                        <Field name="type" label={t('common_phrases.type')} component={renderSelectField}>
                            {this.getCustomerGroupMenuItems()}
                        </Field>
                    </div>
                </div>

                <br />
            </form>
        );
    }

    getCustomerGroupMenuItems() {
        const { t } = this.props;

        return _.map(CustomerGroupType, (type, index) => (
            <MenuItem key={index} value={type} primaryText={t(`customerGroupType.${type.toLowerCase()}`)} />
        ));
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    // validate name
    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/${Entities.CUSTOMER_GROUP.repository}/search/findByName?name=${encodeURIComponent(
            values.name,
        )}`,
        values.id,
        'name',
    );
};

export default reduxForm({
    form: 'customerGroupsEditForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
})(AdminCustomerGroupEditForm);
