import MainCompanyBranchData from 'src/common/entity/main-company-branch-data/MainCompanyBranchData';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchAll, Entities, EntityCollectionState, FetchAll, getEntities } from 'src/common/index';
import ParentForm from 'src/components/misc/parent-form';
import composeModalActions, { InjectedModalProps } from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import { InjectedTranslateProps, StoreState } from 'src/types';
import { i18n } from 'i18next';
import MainCompanyBranchDataForm from './MainCompanyBranchDataForm';

interface MapStateToProps {
    mainCompanyBranches: EntityCollectionState<MainCompanyBranchData>;
}

interface MapDispatchToProps extends FetchAll {}

interface TableDataRow {
    id: number;
    name: string;
    phone: string;
    owner: string;
    uidNumber: string;
    registrationNumber: string;
}

interface TableData extends Array<TableDataRow> {}

interface State {
    startedFetching: boolean;
    renderSelection?: boolean;
}

interface Props extends MapStateToProps, MapDispatchToProps, InjectedModalProps, InjectedTranslateProps {
    i18n: i18n;
}

class MainCompanyBranchDataAdministration extends Component<Props, State> {
    private tableHelper: any;
    private table: any;

    constructor(props: Props) {
        super(props);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    public componentWillMount() {
        this.props.fetchAll(Entities.MAIN_COMPANY_BRANCH_DATA);
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !nextProps.modal.open;
    }

    public componentWillReceiveProps(nextProps: Props) {
        const { mainCompanyBranches } = nextProps;

        if (!this.state.startedFetching && mainCompanyBranches.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(mainCompanyBranches.items.length);
    }

    public componentDidUpdate(prevProps: Props) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.mainCompanyBranches,
                this.props.mainCompanyBranches,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    public render() {
        const { mainCompanyBranches, t } = this.props;
        const finishFetching = this.state.startedFetching && !mainCompanyBranches.isFetching;

        const tableData: TableData = [];

        if (finishFetching && mainCompanyBranches.items.length > 0) {
            mainCompanyBranches.items.map((mainCompanyBranch: MainCompanyBranchData) => {
                tableData.push({
                    id: mainCompanyBranch.id,
                    name: mainCompanyBranch.name,
                    owner: `${mainCompanyBranch.contactData.firstName} ${mainCompanyBranch.contactData.lastName}`,
                    phone: mainCompanyBranch.contactData.phone,
                    registrationNumber: mainCompanyBranch.registrationNumber,
                    uidNumber: mainCompanyBranch.uidNumber,
                });
            });
        }

        return (
            <div>
                <legend className="legend">{t('main_company_branch_data.title')}</legend>

                {(() => {
                    if (mainCompanyBranches.error) return <ErrorMessage object={mainCompanyBranches} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('main_company_branch_data.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="owner" dataSort={true}>
                                    {t('main_company_branch_data.owner')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="registrationNumber" dataSort={true}>
                                    {t('main_company_branch_data.registration_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="uidNumber" dataSort={true}>
                                    {t('main_company_branch_data.uid')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="phone" dataSort={true}>
                                    {t('contact_data.tel_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    private tableActionsFormatter = (cell: any, mainCompanyBranchDataRow: TableDataRow) => {
        const { t } = this.props;

        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.MAIN_COMPANY_BRANCH_DATA,
                                    id: mainCompanyBranchDataRow.id,
                                },
                                childForm: {
                                    component: MainCompanyBranchDataForm,
                                },
                            },
                            title: mainCompanyBranchDataRow.name,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    };
}

const mapStateToProps = (state: StoreState) => {
    return {
        mainCompanyBranches: getEntities(state, Entities.MAIN_COMPANY_BRANCH_DATA),
    };
};

export default withTranslation()(
    connect(mapStateToProps, {
        fetchAll,
    })(composeModalActions(MainCompanyBranchDataAdministration)),
);
