import React from 'react';

export default class DriverRules {
    static getPopulatedDriverProps(allDrivers, driversInRange, driversToPreserve) {
        return allDrivers.map(driver => {
            const foundDriverInRange = driversInRange.find(d => d.id === driver.id);

            if (driver.active === true) {
                if (!foundDriverInRange) return driver;
                else {
                    if (foundDriverInRange.state === 'SHORT_BREAK') {
                        return Object.assign({}, driver, {
                            restedLessThan11Hours: true,
                        });
                    } else if (driversToPreserve && driversToPreserve.length > 0) {
                        if (driversToPreserve.find(d => d === driver._links.self.href)) return driver;
                    }
                }
            }

            return Object.assign({}, driver, {
                restedLessThan9Hours: true,
            });
        });
    }
}

// export default class DriverRules {
//
//     static getAvailableDrivers(allDrivers, driversInRange, driversToPreserve) {
//
//         const availableDrivers = [];
//         allDrivers.forEach(driver => {
//
//             const driverCopy = Object.assign({}, driver);
//             const foundDriverInRange = driversInRange.find(d => d.id === driver.id);
//
//             if (driver.active === true) {
//                 if (!foundDriverInRange)
//                     availableDrivers.push(driverCopy);
//
//                 else {
//                     if (foundDriverInRange.state === "SHORT_BREAK") {
//                         driverCopy.restedLessThan11Hours = true;
//                         availableDrivers.push(driverCopy);
//
//                     } else if (driversToPreserve && driversToPreserve.length > 0) {
//                         if (driversToPreserve.find(d => d === driver._links.self.href))
//                             availableDrivers.push(driverCopy);
//                     }
//                 }
//             }
//         });
//
//         return availableDrivers;
//     }
//
// }
