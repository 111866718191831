import {
    asyncRequest,
    Entities,
    fetchAll,
    fetchById,
    getEntities,
    getUpdatedEntity,
    patch,
    save,
    TOMCAT_URL,
} from 'src/common/index';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { setSliderIndex } from 'src/actions/form';
import IntegrationEditForm from 'src/components/admin/integration/booking/integration-edit-form';
import ErrorMessage from 'src/components/misc/error-message';
import { IntegrationFeeType } from 'src/utils/constants';

class IntegrationEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
            defaultRequestFees: undefined,
        };
    }

    componentWillMount() {
        const { isCreate, id } = this.props;

        if (isCreate)
            asyncRequest(`${TOMCAT_URL}api/${Entities.BOOKING_INTEGRATION.repository}/defaultRequestFees`)
                .then(response => this.setState({ defaultRequestFees: response.json }))
                .catch(error => this.setState({ defaultRequestFees: [] }));
        else this.props.fetchById(id, Entities.BOOKING_INTEGRATION);

        this.props.fetchAll(Entities.BUS_COMPANY);
    }

    componentWillReceiveProps(nextProps) {
        const { busCompanies } = nextProps;

        if (!this.state.startedFetching && busCompanies.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.getWrappedInstance().submit();
    }

    didFormChange() {
        return !this.childForm.getWrappedInstance().pristine;
    }

    isFinishFetching() {
        const { integration, busCompanies, isCreate } = this.props;

        return (
            this.state.startedFetching &&
            !integration.isFetching &&
            !busCompanies.isFetching &&
            ((isCreate && this.state.defaultRequestFees !== undefined) || !isCreate)
        );
    }

    render() {
        const { integration, busCompanies, isCreate, t } = this.props;
        if (this.isFinishFetching()) {
            // check for errors
            if (integration.error) return <ErrorMessage object={integration} />;
            // if everything is ok populate form
            else {
                let initialValues;

                // populate form for editing an existing integration
                if (!isCreate && integration.content)
                    initialValues = {
                        id: integration.content.id,
                        name: integration.content.name,
                        company: `${TOMCAT_URL}api/${Entities.BUS_COMPANY.repository}/${integration.content.company.id}`,
                        url: integration.content.url,
                        active: integration.content.active,
                        feeType: integration.content.feeType,
                        fee: integration.content.fee,
                        requestFees: integration.content.requestFees,
                        allCompanies: integration.content.allCompanies,
                        settings: integration.content.settings,
                        themeOverrides: integration.content.themeOverrides
                            ? JSON.stringify(integration.content.themeOverrides)
                            : '',
                        translationOverrides: integration.content.translationOverrides
                            ? JSON.stringify(integration.content.translationOverrides)
                            : '',
                    };
                else
                    initialValues = {
                        company: '',
                        requestFees: this.state.defaultRequestFees,
                        feeType: IntegrationFeeType.PROVISION,
                    };

                return (
                    <IntegrationEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        integration={integration.content}
                        busCompanies={busCompanies.items}
                        setSliderIndex={this.props.setSliderIndex}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose, closeDrawer } = this.props;

        // replace blanks by underline for integration name
        data.name = data.name.replace(/ /g, '_');

        if (data.feeType === IntegrationFeeType.PRICE_PER_REQUEST) data.fee = null;

        if (data.settings) {
            // when unsetting requireCustomerMail also unset requireCustomerName and sendSearchResultsReminderMail
            if (!data.settings.searchResults || !data.settings.searchResults.requireCustomerMail) {
                if (!data.settings.searchResults) data.settings.searchResults = {};
                data.settings.searchResults.requireCustomerMail = false;
                data.settings.searchResults.requireCustomerName = false;
                data.settings.searchResults.sendSearchResultsReminderMail = false;
            }

            // send null instead of empty strings to unset the default localization values
            if (data.settings.localization) {
                if (!data.settings.localization.defaultLanguage) data.settings.localization.defaultLanguage = null;

                if (!data.settings.localization.defaultRegion) data.settings.localization.defaultRegion = null;

                if (!data.settings.localization.defaultCurrency) data.settings.localization.defaultCurrency = null;
            }
        }

        if (data.translationOverrides) data.translationOverrides = JSON.parse(data.translationOverrides);

        if (data.themeOverrides) data.themeOverrides = JSON.parse(data.themeOverrides);

        if (!isCreate) this.props.patch(data, Entities.BOOKING_INTEGRATION);
        else this.props.save(data, Entities.BOOKING_INTEGRATION);

        if (closeDrawer) closeDrawer();
        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        integration: getUpdatedEntity(state, Entities.BOOKING_INTEGRATION),
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
        selectedSliderIndex: state.selectedSliderIndex,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchAll,
        fetchById,
        patch,
        save,
        setSliderIndex,
    },
    null,
    { withRef: true },
)(IntegrationEditModal);
