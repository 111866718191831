import React, { Component } from 'react';
import autoBind from 'react-autobind';
import TableHelper from 'src/utils/table-helper';
import { withTranslation } from 'react-i18next';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { Entities, Projection } from 'src/common';
import CircularProgress from 'material-ui/CircularProgress';
import ErrorMessage from 'src/components/misc/error-message';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Toggle from 'material-ui/Toggle';
import CustomTooltip from 'src/components/misc/CustomTooltip';

class CustomerGroupSettingsAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchOrCreateByCompanyId(
            Entities.CUSTOMER_GROUP_SETTINGS,
            this.props.selectedCompany,
            'getCustomerGroupSettings',
            Projection.FULL,
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.customerGroupSettings,
                this.props.customerGroupSettings,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    componentWillReceiveProps(nextProps) {
        const { customerGroupSettings } = nextProps;
        if (customerGroupSettings.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(customerGroupSettings.length);
    }

    render() {
        const { company, customerGroupSettings, t } = this.props;
        const finishFetching = this.state.startedFetching && !customerGroupSettings.isFetching;
        const tableData = [];

        if (finishFetching && customerGroupSettings !== undefined) {
            customerGroupSettings.items.forEach(customerGroupSetting => {
                tableData.push({
                    id: customerGroupSetting.id,
                    customerGroup: customerGroupSetting.customerGroup,
                    customerGroupName: customerGroupSetting.customerGroup.name,
                    invoicePaymentEnabled: customerGroupSetting.invoicePaymentEnabled,
                });
            });
        }

        return (
            <div>
                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (customerGroupSettings.error) return <ErrorMessage object={customerGroupSettings} />;
                    else
                        return (
                            <div>
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}
                                    />
                                    <TableHeaderColumn columnTitle={true} dataField="customerGroupName" dataSort={true}>
                                        {t('customer_group.groups')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="invoicePaymentEnabled"
                                        dataSort={true}
                                        dataFormat={(cell, row) =>
                                            this.toggleFormatter(cell, row, 'invoicePaymentEnabled')
                                        }>
                                        {t('customer_group.invoice_payment_allowed')}&nbsp;
                                        <CustomTooltip
                                            text={
                                                <span style={{ maxWidth: 200 }}>
                                                    {t('customer_group.information_invoice_payment_allowed')}
                                                </span>
                                            }>
                                            <span className="fa fa fa-info-circle text-info" />
                                        </CustomTooltip>
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    toggleFormatter(cell, row, field) {
        return (
            <Toggle
                toggled={row[field]}
                onToggle={() => {
                    this.props.patch(
                        {
                            id: row.id,
                            [field]: !row[field],
                            CustomerGroupSettings: row,
                        },
                        Entities.CUSTOMER_GROUP_SETTINGS,
                        this.props.selectedCompany,
                        Projection.FULL,
                        null,
                    );
                }}
            />
        );
    }
}

export default withTranslation()(composeModalActions(CustomerGroupSettingsAdministration));
