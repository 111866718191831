import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import Drawer from 'material-ui/Drawer';

import { ButtonColors, CustomerGroupType, genderName } from 'src/utils/constants';
import { asyncRequest, Entities, TOMCAT_URL, UserRole } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import PasswordEditModal from 'src/components/account/password-edit-modal';
import AdminCustomerVerificationModal from 'src/components/admin/accounts/admin-customer-verification-modal';
import ErrorMessage from 'src/components/misc/error-message';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import ParentForm from 'src/components/misc/parent-form';
import AdminCustomerContactForm from 'src/components/admin/accounts/AdminCustomerContactForm';
import { withTranslation } from 'react-i18next';

class AdminCustomerAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            drawer: {
                open: false,
                selectedRow: undefined,
            },
            startedFetching: false,
        };

        this.tableHelper = new TableHelper({ onRowClick: this.onRowClick });
    }

    componentWillMount() {
        this.props.fetchAll(Entities.CUSTOMER, 0, 'verification');
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { customers } = nextProps;

        if (!this.state.startedFetching && customers.isFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(customers.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.customers, this.props.customers, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { customers, t } = this.props;
        const finishFetching = this.state.startedFetching && !customers.isFetching;

        const tableData = [];

        if (finishFetching) {
            customers.items.map(account => {
                tableData.push({
                    id: account.id,
                    email: account.email,
                    fullName: account.contactData.fullName,
                    gender: account.contactData.gender,
                    address: account.contactData.address,
                    registered: account.registered,
                    verified: account.verified,
                    verifiedAt: account.verifiedAt,
                    verifiedBy: account.verifiedBy,
                    verificationComment: account.verificationComment,
                    subscribedToNewsletter: account.subscribedToNewsletter,
                    organizationName: account.organizationName,
                    organizationLocation: account.organizationLocation && account.organizationLocation.address,
                    customerGroup: account.customerGroup,
                    customerGroupName: account.customerGroup ? account.customerGroup.name : '',
                    entity: account,
                    apiCustomer: account.apiCustomer,
                });
            });
        }

        return (
            <div>
                <legend className="legend">{t('customer_details.customer')}</legend>

                {(() => {
                    if (customers.error) return <ErrorMessage object={customers} />;
                    else
                        return (
                            <div>
                                <Drawer
                                    openSecondary={true}
                                    docked={false}
                                    width={400}
                                    onRequestChange={open =>
                                        this.setState({
                                            drawer: {
                                                open: false,
                                                selectedRow: undefined,
                                            },
                                        })
                                    }
                                    open={this.state.drawer.open}>
                                    {this.state.drawer.open && this.state.drawer.selectedRow
                                        ? renderCustomerDetails(
                                              t,
                                              this.state.drawer.selectedRow.entity,
                                              this.state.drawer.selectedRow.entity.contactData,
                                          )
                                        : null}
                                </Drawer>

                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="email" width="200" dataSort={true}>
                                        {t('contact_data.username')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="customerGroupName"
                                        width="140"
                                        dataSort={true}>
                                        {t('customer_details.customer_group')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="gender"
                                        dataSort={true}
                                        width="100"
                                        dataFormat={genderName}
                                        csvFormat={genderName}>
                                        {t('contact_data.gender')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="fullName"
                                        width="180"
                                        dataSort={true}>
                                        {t('common_phrases.name')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                                        {t('customer_details.address')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="registered"
                                        width="120"
                                        dataSort={true}
                                        dataFormat={statusFormatter}>
                                        {t('customer_details.registered')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="verified"
                                        width="100"
                                        dataSort={true}
                                        dataFormat={this.verifiedFormatter}>
                                        {t('customer_details.verified')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="subscribedToNewsletter"
                                        width="120"
                                        dataSort={true}
                                        dataFormat={statusFormatter}>
                                        {t('customer_details.newsletter')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="120"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    verifiedFormatter(cell, account) {
        const { t } = this.props;
        if (cell)
            return (
                <span
                    className="fa fa-check-circle-o text-success fa-lg"
                    title={t('administration.verified_info', {
                        date: moment(account.verifiedAt).format(),
                        email: account.verifiedBy.email,
                        verificationComment: account.verificationComment,
                    })}
                />
            );
        else
            return (
                <span className="fa fa-times-circle-o text-danger fa-lg" title={t('customer_details.not_verified')} />
            );
    }

    tableActionsFormatter(cell, account) {
        const { addNotification, t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.CUSTOMER,
                                    id: account.id,
                                },
                                projection: 'verification',
                                childForm: {
                                    component: AdminCustomerContactForm,
                                },
                                closeDrawer: this.handleCloseDrawer,
                            },
                            title: account.email,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                {!account.verified && (
                    <button
                        type="button"
                        className="btn btn-xs transparent"
                        title={t('customer_details.verify')}
                        onClick={() => {
                            this.props.openModal({
                                cancelCallback: this.handleCloseDrawer,
                                component: AdminCustomerVerificationModal,
                                componentProps: {
                                    id: account.id,
                                    closeDrawer: this.handleCloseDrawer,
                                },
                                title: t('customer_details.verify_client', { email: account.email }),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-check text-success" />
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('customer_details.change_password')}
                    onClick={() => {
                        this.props.openModal({
                            component: PasswordEditModal,
                            cancelCallback: this.handleCloseDrawer,
                            componentProps: {
                                id: account.id,
                                role: UserRole.CUSTOMER,
                                isAdmin: true,
                                closeDrawer: this.handleCloseDrawer,
                            },
                            title: t('customer_details.change_password_client', { fullName: account.fullName }),
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-lock text-info" />
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('customer_details.password_reset_link')}
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                closeDrawer: this.handleCloseDrawer,
                                bodyText: t('customer_details.confirm_password_reset_link'),
                                proceedText: t('user_functionality.send'),
                                cancelText: t('common_phrases.no'),
                                cancelCallback: this.handleCloseDrawer,
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                                event: account.email,
                                onProceedDialog: email =>
                                    asyncRequest({
                                        method: 'POST',
                                        endpoint: `${TOMCAT_URL}api/admin/send-password-reset-link`,
                                        body: {
                                            email: email,
                                        },
                                    })
                                        .then(response =>
                                            addNotification(
                                                t('customer_details.sent'),
                                                t('customer_details.sent_password_reset_link'),
                                                'success',
                                                'tr',
                                            ),
                                        )
                                        .catch(error =>
                                            addNotification(
                                                t('common_phrases.error'),
                                                t('customer_details.error_email_sending'),
                                                'error',
                                                'tr',
                                            ),
                                        ),
                            },
                            title: t('customer_details.reset_link_customer', { email: account.email }),
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-refresh text-info" />
                </button>
            </div>
        );
    }

    onRowClick(row) {
        this.tableHelper.setSelectedRow(row.entity.id);
        this.setState({
            drawer: {
                open: !this.state.drawer.open,
                selectedRow: row,
            },
        });
    }

    handleCloseDrawer() {
        this.setState({
            drawer: {
                open: !this.state.drawer.open,
                selectedRow: undefined,
            },
        });
    }
}

export default composeModalActions(withTranslation()(AdminCustomerAdministration));

const dateFormatter = (date, t) => {
    return date ? t('administration.time', { time: moment(date).format() }) : '-';
};

export const statusFormatter = cell => {
    if (cell) return <span className="fa fa-check-circle-o text-success fa-lg" />;
    else return <span className="fa fa-times-circle-o text-danger fa-lg" />;
};

export const renderCustomerDetails = (t, customer, contactData, onChangeTaskContactData) => {
    return (
        <div>
            <div className="calculactionsdetails-header">
                <h5>{t('customer_details.customer_information')}</h5>
            </div>
            <table className="table table-striped voffset">
                <thead>
                    <tr>
                        <th>{t('customer_details.contact_data')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="col-md-3">{t('contact_data.gender')}</td>
                        <td className="col-md-9">{genderName(contactData.gender)}</td>
                    </tr>
                    <tr>
                        <td className="col-md-3">{t('common_phrases.name')}</td>
                        <td className="col-md-9">
                            {contactData.fullName}
                            {onChangeTaskContactData && (
                                <button
                                    type="button"
                                    className="btn btn-xs transparent"
                                    title={t('customer_details.edit_name')}
                                    onClick={onChangeTaskContactData}>
                                    <span className="glyphicon glyphicon-pencil text-info" />
                                </button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="col-md-3">{t('customer_details.address')}</td>
                        <td className="col-md-9">{contactData.address}</td>
                    </tr>
                    <tr>
                        <td className="col-md-3">{t('customer_details.email')}</td>
                        <td className="col-md-9">{contactData.email}</td>
                    </tr>
                    <tr>
                        <td className="col-md-3">{t('customer_details.tel_number')}</td>
                        <td className="col-md-9">{contactData.phone}</td>
                    </tr>
                </tbody>
            </table>

            {customer && (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('customer_details.customer_group')}</th>
                        </tr>
                    </thead>
                    {customer.customerGroup.type === CustomerGroupType.PRIVATE ? (
                        <tbody>
                            <tr>
                                <td className="col-md-3">{t('customer_details.customer_group')}</td>
                                <td className="col-md-9">{customer.customerGroup.name}</td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td className="col-md-3">{t('customer_details.customer_group')}</td>
                                <td className="col-md-9">{customer.customerGroup.name}</td>
                            </tr>
                            <tr>
                                <td className="col-md-3">{t('customer_details.organisation')}</td>
                                <td className="col-md-9">{customer.organizationName}</td>
                            </tr>
                            <tr>
                                <td className="col-md-3">{t('common_phrases.location')}</td>
                                <td className="col-md-9">
                                    {customer.organizationLocation != null ? customer.organizationLocation.address : ''}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            )}

            {customer && (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('customer_details.account')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-md-3">{t('contact_data.username')}</td>
                            <td className="col-md-9">{customer.email}</td>
                        </tr>
                        <tr>
                            <td className="col-md-3">{t('customer_details.registered')}</td>
                            <td className="col-md-9">
                                {statusFormatter(customer.registered)}{' '}
                                {customer.registered && dateFormatter(customer.registeredAt, t)}
                            </td>
                        </tr>
                        <tr>
                            <td className="col-md-3">{t('customer_details.verified')}</td>
                            <td className="col-md-9">
                                {statusFormatter(customer.verified)}{' '}
                                {customer.verified && dateFormatter(customer.verifiedAt, t)}
                            </td>
                        </tr>
                        <tr>
                            <td className="col-md-3">{t('customer_details.newsletter')}</td>
                            <td className="col-md-9">{statusFormatter(customer.subscribedToNewsletter)}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};
