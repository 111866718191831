import { Entities, SanitizedInnerHTML, TOMCAT_URL, asyncRequest } from 'src/common/index';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import EventModal from 'src/components/events/event-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import PrintOfferModal from 'src/components/taskadministration/print-offer-modal';
import PrintTaskModal from 'src/components/taskadministration/print-task-modal';
import SendDriverDetailsToCustomerModal from 'src/components/taskadministration/senddriverdetails/SendDriverDetailsToCustomerModal';
import TaskExchangeModal from 'src/components/taskadministration/taskrelease/task-exchange-modal';
import { ButtonColors, EventType, OfferStatus, TaskType } from 'src/utils/constants';
import { emptyRichText } from 'src/utils/helpers';

export default class TaskActionButtonHelper {
    constructor(props) {
        this.props = props;

        if (!props.openModal)
            console.error('openModal ist not defined! please wrap the calling component with composeModalActions()');
    }

    getTaskActionButtonsByTask(props) {
        const { task } = props;
        const actionButtons = [];

        const taskNotesButton = this.getTaskNotesButton(props);
        if (taskNotesButton != null) actionButtons.push(taskNotesButton);

        actionButtons.push(
            this.getPrintEventButton(props, task.offerStatus != null && task.offerStatus !== OfferStatus.ACCEPTED),
            this.getLoadTaskIntoRoutePlannerButton(props),
            this.getAmendBookingButton(props)
        );

        if (this.props.isOfferView) {
            if (task.type === TaskType.OPEN_OFFER) {
                actionButtons.push(this.getOpenOfferButtons(props));
            }
            // offer tasks cannot be edited in calendar anymore
        } else {
            if (props.externTasks && task.externTaskId) {
                actionButtons.push(this.getCancelDisposeExternTaskButton(props));
            } else {
                switch (task.type) {
                    case TaskType.MY_OPEN_TASK:
                        actionButtons.push(this.getTaskDuplicateButton(props));
                        actionButtons.push(this.getTaskEditButton(props));

                        if (task.offerStatus == null) actionButtons.push(this.getTaskDeleteButton(props));

                        if (moment().isBefore(moment(task.from.time))) {
                            actionButtons.push(this.getTaskReleaseButton(props));
                        }

                        if (props.externTasks && !task.externTaskId)
                            actionButtons.push(this.getDisposeTaskExtern(props));

                        break;

                    case TaskType.MY_ASSIGNED_TASK:
                        if (moment().isBefore(moment(task.from.time))) {
                            actionButtons.push(this.getTaskDuplicateButton(props));
                            actionButtons.push(this.getTaskEditButton(props));

                            if (task.offerStatus == null) actionButtons.push(this.getTaskDeleteButton(props));

                            if (props.externTasks && !task.externTaskId)
                                actionButtons.push(this.getDisposeTaskExtern(props));
                        }
                        break;

                    case TaskType.BOOKED_BY_CUSTOMER_OPEN:
                        actionButtons.push(this.getTaskEditButton(props));

                        if (task.reservation && !task.cancelled && moment().isBefore(moment(task.from.time)))
                            actionButtons.push(this.getCancelReservationButton(props));

                        if (props.externTasks && !task.externTaskId)
                            actionButtons.push(this.getDisposeTaskExtern(props));

                        if (props.csBusCompany) actionButtons.push(this.getExportToCsBusButton(props));

                        break;

                    case TaskType.BOOKED_BY_CUSTOMER_ASSIGNED:
                        if (moment().isBefore(moment(task.from.time))) {
                            actionButtons.push(this.getTaskEditButton(props));

                            if (!task.cancelled) {
                                actionButtons.push(this.getSendDriverDetailsToCustomerButton(props));
                                if (task.reservation) actionButtons.push(this.getCancelReservationButton(props));
                                if (!task.cancelled && !task.received && !moment(task.from).isAfter(moment()))
                                    actionButtons.push(this.getReceivedTaskButton(props));
                            }
                        }

                        if (props.reviews && task.customer && task.review && props.reviews[task.review].visible)
                            actionButtons.push(this.getDisplayTaskReviewButton(props));

                        if (props.externTasks && !task.externTaskId)
                            actionButtons.push(this.getDisposeTaskExtern(props));

                        if (props.csBusCompany) actionButtons.push(this.getExportToCsBusButton(props));

                        break;

                    case TaskType.OPEN_OFFER:
                        break;
                    case TaskType.MY_OPEN_EXCHANGED_TASK_PENDING:
                        actionButtons.push(this.getDeletePendingTaskButton(props));
                        break;

                    case TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING:
                        actionButtons.push(this.getTaskAcceptButton(props));

                        if (task.exchangedTasks[0].foreignCompanyDetails.id !== -1)
                            actionButtons.push(this.getDeclinePendingTaskButton(props));
                        break;

                    case TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED:
                        if (moment().isBefore(moment(task.from.time))) {
                            actionButtons.push(this.getTaskEditButton(props));
                            actionButtons.push(this.getTaskDeleteButton(props));
                        }
                        break;

                    case TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK:
                        if (moment().isBefore(moment(task.from.time))) {
                            actionButtons.push(this.getTaskEditButton(props));
                            actionButtons.push(this.getTaskDuplicateButton(props));
                            actionButtons.push(this.getTaskDeleteButton(props));
                        }
                        break;

                    default:
                        return actionButtons;
                }
            }
        }

        return actionButtons;
    }

    getTaskNotesButton(props) {
        const { task, withText, t } = props;

        if (!task.notes || task.notes === emptyRichText) return null;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            bodyText: <SanitizedInnerHTML html={task.notes} />,
                            proceedText: t('common_phrases.ok'),
                            proceedButtonColor: ButtonColors.NOTIFY,
                            isNotification: true,
                        },
                        title: t('events.notes'),
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-info-sign text-info" title={t('events.notes')} />
                {withText && ' ' + t('events.notes')}
            </button>
        );
    }

    getTaskAcceptButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedAcceptTask,
                            bodyText: t('tasks.confirm_accept_released_task'),
                            proceedText: t('user_functionality.accept'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.SUCCESS,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-ok text-success" title={t('user_functionality.accept')} />
                {withText && ' ' + t('user_functionality.accept')}
            </button>
        );
    }

    getTaskDuplicateButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedDuplicateTask,
                            bodyText: t('tasks.confirm_duplicate_task'),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.SUCCESS,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="fa fa-files-o text-warning" title={t('user_functionality.duplicate')} />
                {withText && ' ' + t('user_functionality.duplicate')}
            </button>
        );
    }

    getTaskEditButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() =>
                    openEventModal({
                        openModal: this.props.openModal,
                        title: task.name,
                        id: task.id,
                        isOffer: task.type === TaskType.OPEN_OFFER,
                        eventType: EventType.TASK,
                    })
                }>
                <span className="glyphicon glyphicon-pencil text-info" title={t('user_functionality.edit')} />
                {withText && ' ' + t('user_functionality.edit')}
            </button>
        );
    }

    getTaskDeleteButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedDeleteTask,
                            bodyText: t('tasks.confirm_delete'),
                            proceedText: t('user_functionality.delete'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-trash text-danger" title={t('user_functionality.delete')} />
                {withText && ' ' + t('user_functionality.delete')}
            </button>
        );
    }

    getTransformOfferToTaskButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedTransformOfferToTask,
                            bodyText: t('tasks.confirm_convert_offer_to_task'),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.SUCCESS,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-ok text-success" title={t('user_functionality.accept')} />
                {withText && ' ' + t('user_functionality.accept')}
            </button>
        );
    }

    getTaskReleaseButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    if (!task.firstDriver && !task.secondDriver) {
                        this.props.openModal({
                            component: TaskExchangeModal,
                            componentProps: {
                                task: task,
                                dialogStyle: {
                                    width: '70%',
                                    maxWidth: 'none',
                                },
                            },
                            title: `${task.name}`,
                            successButtonLabel: t('user_functionality.release'),
                        });
                    } else {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: {
                                    task: task,
                                    onSuccessRemoveDrivers: task => {
                                        this.props.openModal({
                                            component: TaskExchangeModal,
                                            componentProps: {
                                                task: task,
                                                dialogStyle: {
                                                    width: '70%',
                                                    maxWidth: 'none',
                                                },
                                            },
                                            title: `${task.name}`,
                                            successButtonLabel: t('user_functionality.release'),
                                        });
                                    },
                                },
                                onProceedDialog: this.props.onProceedRemoveDrivers,
                                bodyText: t('tasks.hint_drivers_on_release_task'),
                                proceedText: t('common_phrases.ok'),
                                cancelText: t('common_phrases.abort'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.SUCCESS,
                            },
                            title: t('common_phrases.attention'),
                            successButtonLabel: t('user_functionality.release'),
                            noButtons: true,
                        });
                    }
                }}>
                <span className="glyphicon glyphicon-transfer text-warning" title={t('user_functionality.release')} />
                {withText && ' ' + t('user_functionality.release')}
            </button>
        );
    }

    getDeletePendingTaskButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedDeletePendingTask,
                            bodyText: t('tasks.confirm_delete_pending_task'),
                            proceedText: t('task_details.remove'),
                            cancelText: t('common_phrases.abort'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-remove text-danger" title={t('user_functionality.stop_release')} />
                {withText && ' ' + t('user_functionality.stop_release')}
            </button>
        );
    }

    getDeclinePendingTaskButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedDeclineTask,
                            bodyText: t('booked_tasks.confirm_denial'),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="fa fa-ban text-danger" title={t('user_functionality.decline')} />
                {withText && ' ' + t('user_functionality.decline')}
            </button>
        );
    }

    getPrintEventButton(props, printOffer) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: printOffer ? PrintOfferModal : PrintTaskModal,
                        componentProps: {
                            id: task.id,
                        },
                        title: printOffer ? t('offers.print') : t('tasks.print'),
                        successButtonLabel: t('user_functionality.print'),
                    });
                }}>
                <span className="fa fa-print" title={t('user_functionality.print')} />
                {withText && ' ' + t('user_functionality.print')}
            </button>
        );
    }

    getDisplayTaskReviewButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                title={t('user_functionality.view_review')}
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            bodyText: getReviewDetails(t, task, props.reviews),
                            proceedText: t('common_phrases.ok'),
                            proceedButtonColor: ButtonColors.NOTIFY,
                            isNotification: true,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="fa fa-eye text-warning" title={t('user_functionality.view_review')} />
                {withText && ' ' + t('common_phrases.rating')}
            </button>
        );
    }

    getCancelReservationButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedCancelReservation,
                            bodyText: t('tasks.confirm_cancellation'),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: task.identifier,
                        noButtons: true,
                    });
                }}>
                <span className="fa fa-ban text-danger" title={t('tasks.cancel_reservation')} />
                {withText && ' ' + t('user_functionality.cancel')}
            </button>
        );
    }

    getReceivedTaskButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedReceivedTask,
                            bodyText: t('booked_tasks.received_information'),
                            proceedText: t('booked_tasks.mark_received'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.SUCCESS,
                        },
                        title: task.identifier,
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-ok text-success" title={t('booked_tasks.mark_received')} />
                {withText && ' ' + t('booked_tasks.mark_received')}
            </button>
        );
    }

    getDisposeTaskExtern(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedDisposeTaskExtern,
                            bodyText: t('tasks.confirm_dispose_extern_task'),
                            proceedText: t('user_functionality.dispose_extern'),
                            cancelText: t('common_phrases.abort'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.SUCCESS,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span
                    className="glyphicon glyphicon-transfer text-success"
                    title={t('user_functionality.dispose_extern')}
                />
                {withText && ' ' + t('user_functionality.dispose_extern')}
            </button>
        );
    }

    getCancelDisposeExternTaskButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedCancelDisposeExternTask,
                            bodyText: t('tasks.confirm_revert_dispose_extern_task'),
                            proceedText: t('user_functionality.revert_dispose_extern'),
                            cancelText: t('common_phrases.abort'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="fa fa-ban text-danger" title={t('user_functionality.revert_dispose_extern')} />
                {withText && ' ' + t('user_functionality.revert_dispose_extern')}
            </button>
        );
    }

    getSendDriverDetailsToCustomerButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: SendDriverDetailsToCustomerModal,
                        componentProps: {
                            id: task.id,
                        },
                        title: task.name,
                        successButtonLabel: t('user_functionality.send'),
                    });
                }}>
                <span
                    className={`fa fa-envelope text-info ${task.remindToSendDriverDetailsToCustomer && 'blink'}`}
                    title={
                        task.remindToSendDriverDetailsToCustomer
                            ? t('booked_tasks.driver_details_due_now')
                            : t('user_functionality.send_driver_details_to_customer')
                    }
                />
                {withText && ' ' + t('user_functionality.driver_details_to_customer')}
            </button>
        );
    }

    getLoadTaskIntoRoutePlannerButton(props) {
        const { task, t, withText } = props;

        const { bookingRoutePlannerUrl } = task;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    window.open(bookingRoutePlannerUrl, '_blank');
                }}>
                <span
                    className="glyphicon glyphicon-new-window text-info"
                    title={t('user_functionality.open_in_route_planner')}
                />
                {withText && ' ' + t('user_functionality.open_in_route_planner')}
            </button>
        );
    }

    getAmendBookingButton(props) {
        const { task, t, withText } = props;

        const { amendBookingUrl } = task;

        if (!amendBookingUrl) return null;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    window.open(amendBookingUrl, '_blank');
                }}>
                <span
                    className="glyphicon glyphicon glyphicon-edit text-info"
                    title={t('user_functionality.amend_in_route_planner')}
                />
                {withText && ' ' + t('user_functionality.amend_in_route_planner')}
            </button>
        );
    }

    getExportToCsBusButton(props) {
        const { task, withText, t } = props;

        return (
            <button
                type="button"
                className="btn btn-xs transparent"
                onClick={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: this.props.onProceedExportToCsBus,
                            bodyText: t('tasks.export_to_cs_bus'),
                            proceedText: t('common_phrases.export'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.SUCCESS,
                        },
                        title: task.name,
                        noButtons: true,
                    });
                }}>
                <span className="glyphicon glyphicon-export text-danger" title={t('tasks.export_to_cs_bus')} />
                {withText && ' ' + t('tasks.export_to_cs_bus')}
            </button>
        );
    }

    getOpenOfferButtons(props) {
        const { task, withText, t } = props;

        return (
            <>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                onProceedDialog: () => this.props.onResendOffer(task),
                                bodyText: t('offers.confirm_resend'),
                                proceedText: t('offers.resend'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.DANGER,
                                proceedButtonColor: ButtonColors.NOTIFY,
                            },
                            title: task.name,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-envelope text-info" title={t('offers.resend')} />
                    {withText && ' ' + t('offers.resend')}
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: task,
                                onProceedDialog: this.props.onProceedAcceptOffer,
                                bodyText: t('offers.confirm_accept'),
                                proceedText: t('offers.accept_offer'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.DANGER,
                                proceedButtonColor: ButtonColors.NOTIFY,
                            },
                            title: task.name,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-ok text-success" title={t('offers.accept_offer')} />
                    {withText && ' ' + t('offers.accept_offer')}
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: task,
                                onProceedDialog: this.props.onProceedDeclineOffer,
                                bodyText: t('offers.confirm_decline'),
                                proceedText: t('offers.decline_offer'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                            },
                            title: task.name,
                            noButtons: true,
                        });
                    }}>
                    <span
                        className="glyphicon glyphicon-ban-circle text-warning"
                        title={t('user_functionality.decline')}
                    />
                    {withText && ' ' + t('user_functionality.decline')}
                </button>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ConfirmDialog,
                            componentProps: {
                                event: task,
                                onProceedDialog: this.props.onProceedDeleteOffer,
                                bodyText: t('offers.confirm_delete'),
                                proceedText: t('offers.delete_offer'),
                                cancelText: t('common_phrases.no'),
                                cancelButtonColor: ButtonColors.NOTIFY,
                                proceedButtonColor: ButtonColors.DANGER,
                            },
                            title: task.name,
                            noButtons: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-trash text-danger" title={t('user_functionality.delete')} />
                    {withText && ' ' + t('user_functionality.delete')}
                </button>
            </>
        );
    }
}

const openEventModal = props => {
    props.openModal({
        component: EventModal,
        componentProps: {
            id: props.id,
            eventType: props.eventType,
            isCreate: props.isCreate ? props.isCreate : false,
            isOffer: props.isOffer ? props.isOffer : false,
            initialValuesOnCreate: {
                start: props.start,
                end: props.end,
                fromLocation: props.busCompany && props.busCompany.entity.location,
                busId: props.busId,
                name: props.name,
            },
        },
        title: props.title,
        mandatoryFields: true,
    });
};

const getReviewDetails = (t, task, reviews) => {
    const taskReview = reviews[task.review];

    let reviewEntries;
    if (taskReview['entries']) reviewEntries = _.toPairs(taskReview['entries']);

    return (
        <div>
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('common_phrases.category')}</th>
                            <th>{t('common_phrases.rating')}</th>
                        </tr>
                    </thead>

                    {reviewEntries && reviewEntries.length > 0 ? (
                        <tbody>
                            {reviewEntries.map((reviewEntry, key) => (
                                <tr key={key}>
                                    <td>{reviewEntry[0]}</td>
                                    <td>{reviewEntry[1] ? reviewEntry[1].toFixed(2) : reviewEntry[1]}</td>
                                </tr>
                            ))}
                            <tr>
                                <td className="success">
                                    <strong>{t('common_phrases.overall')}</strong>
                                </td>
                                <td className="success">
                                    <strong>{taskReview.totalScore.toFixed(2)}</strong>
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td>{t('task_details.no_ratings')}</td>
                            </tr>
                        </tbody>
                    )}
                </table>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('comment')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{taskReview.comment}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
