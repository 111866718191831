import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Entities } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ErrorMessage from 'src/components/misc/error-message';
import ParentForm from 'src/components/misc/parent-form';
import SchoolEditForm from 'src/components/admin/schools/school-edit-form';
import { withTranslation } from 'react-i18next';
import { asyncRequest, TOMCAT_URL } from 'src/common/index';

class AdminSchoolAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.SCHOOL, 0, '');
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { schools } = nextProps;

        if (schools.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(schools.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.processPaginationAfterUpdate(prevProps.schools, this.props.schools, this.table))
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { schools, t } = this.props;
        const finishFetching = this.state.startedFetching && !schools.isFetching;

        const tableData = [];
        if (finishFetching && schools.items.length > 0) {
            schools.items.map(school => {
                tableData.push({
                    id: school.id,
                    schoolName: school.schoolName,
                    address: school.contactData.address,
                    email: school.contactData.email,
                    phone: school.contactData.phone,
                    busPoints: school.busPoints,
                });
            });
        }

        return (
            <div>
                {(() => {
                    if (schools.error) return <ErrorMessage object={schools} />;
                    else
                        return (
                            <div>
                                <legend className="legend">
                                    {t('schools.school')}
                                    <button
                                        className="btn btn-xs btn-link nav-link pull-right"
                                        onClick={() => {
                                            this.table.reset();
                                            this.props.openModal({
                                                component: ParentForm,
                                                componentProps: {
                                                    isCreate: true,
                                                    mainEntityProps: {
                                                        entity: Entities.SCHOOL,
                                                        id: undefined,
                                                    },
                                                    childForm: {
                                                        component: SchoolEditForm,
                                                    },
                                                },
                                                title: t('schools.new_school'),
                                            });
                                        }}>
                                        <span className="glyphicon glyphicon-plus" />
                                        &nbsp;{t('schools.new_school')}
                                    </button>
                                    <button
                                        className="btn btn-xs btn-link nav-link pull-right"
                                        onClick={() => {
                                            asyncRequest({
                                                endpoint: `${TOMCAT_URL}api/admin/calculateBusPoints`,
                                                method: 'POST',
                                            })
                                                .then(() => alert(t('common_phrases.ok')))
                                                .catch(error => alert(t('error', { error: JSON.stringify(error) })));
                                        }}>
                                        <span className="glyphicon glyphicon-refresh" />
                                        &nbsp;{t('schools.calculate_bus_points')}
                                    </button>
                                </legend>
                                <BootstrapTable
                                    ref={ref => (this.table = ref)}
                                    data={tableData}
                                    exportCSV={true}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    pagination={true}
                                    options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                    selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                    searchPlaceholder={t('common_phrases.search')}
                                    search={true}>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="id"
                                        hidden={true}
                                        isKey={true}
                                        export={true}>
                                        {t('common_phrases.id')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="schoolName" dataSort={true}>
                                        {t('schools.school')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                                        {t('schools.address')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="email" dataSort={true}>
                                        {t('schools.email')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn columnTitle={true} dataField="phone" width="160" dataSort={true}>
                                        {t('schools.telephone')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="busPoints"
                                        width="110"
                                        dataSort={true}>
                                        {t('schools.bus_points')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="actions"
                                        width="90"
                                        dataFormat={this.tableActionsFormatter}
                                        export={false}>
                                        {t('common_phrases.actions')}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        );
                })()}
            </div>
        );
    }

    tableActionsFormatter(cell, school) {
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: ParentForm,
                            componentProps: {
                                isCreate: false,
                                mainEntityProps: {
                                    entity: Entities.SCHOOL,
                                    id: school.id,
                                },
                                projection: 'bus-points',
                                childForm: {
                                    component: SchoolEditForm,
                                },
                            },
                            title: school.schoolName,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(AdminSchoolAdministration));
