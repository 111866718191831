import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { renderCheckbox, renderInput } from 'src/components/misc/redux-form-helpers';
import { Entities, isInteger, getEntityLinkById } from 'src/common/index';
import DateTimePicker from 'src/components/misc/DateTimePicker';
import ParentForm from 'src/components/misc/parent-form';
import { isBlank } from 'src/components/misc/validations';

class TripBlockageForm extends Component {
    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                {/* Name of Blockage */}
                <div className="row">
                    <div className="col-md-12">
                        <Field name="name" label={`${t('common_phrases.name')} *`} component={renderInput} />
                    </div>
                </div>

                {/* Start and End of */}
                <div className="row">
                    <div className="col-md-6">
                        <Field name="from" label={`${t('booked_tasks.from')} *`} component={DateTimePicker} />
                    </div>
                    <div className="col-md-6">
                        <Field name="to" label={`${t('booked_tasks.to')} *`} component={DateTimePicker} />
                    </div>
                </div>

                {/* Pax Reduction */}
                <div className="row">
                    <div className="col-md-6 voffset40">
                        <Field name="fullBlock" label={t('blockage.full_block')} component={renderCheckbox} />
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="reducedPax"
                            label={`${t('blockage.reduced_pax')} *`}
                            type="number"
                            component={renderInput}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!isInteger(values.reducedPax)) errors.reducedPax = t('error_hint.error_integer');
    if (values.reducedPax && values.reducedPax < 0) errors.reducedPax = t('error_hint.error_negative');

    if (!values.from) errors.from = t('blockage.fill_in_from');
    else if (values.to && moment(values.from, 'DD.MM.YYYY HH:mm').isSameOrAfter(moment(values.to, 'DD.MM.YYYY HH:mm')))
        errors.from = t('blockage.error_from_before_to');

    if (!values.to) errors.to = t('blockage.fill_in_to');
    else if (
        values.from &&
        moment(values.to, 'DD.MM.YYYY HH:mm').isSameOrBefore(moment(values.from, 'DD.MM.YYYY HH:mm'))
    )
        errors.to = t('blockage.error_to_after_from');

    return errors;
};

const onSubmit = (data, submit) => {
    // transform date for backend
    data.from = moment(data.from, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');
    data.to = moment(data.to, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD[T]HH:mm');

    submit(data);
};

TripBlockageForm = reduxForm({
    form: 'TripBlockageForm',
    validate,
})(TripBlockageForm);

const mapStateToProps = (state, ownProps) => {
    const { isCreate, start, end, tripBlockage, companyId } = ownProps;

    const companyLink = getEntityLinkById(Entities.TRIP_COMPANY, companyId);

    let initialValues;

    if (!isCreate && tripBlockage)
        initialValues = {
            id: tripBlockage.id,
            name: tripBlockage.name,
            from: moment(tripBlockage.from).format('DD.MM.YYYY HH:mm'),
            to: moment(tripBlockage.to).format('DD.MM.YYYY HH:mm'),
            fullBlock: tripBlockage.fullBlock,
            reducedPax: tripBlockage.reducedPax,
            company: companyLink,
        };
    else
        initialValues = {
            from: start.format('DD.MM.YYYY HH:mm'),
            to: end.format('DD.MM.YYYY HH:mm'),
            fullBlock: true,
            company: companyLink,
        };

    return {
        initialValues: initialValues,
        onSubmit: data => onSubmit(data, ownProps.onSubmit),
    };
};

TripBlockageForm = connect(mapStateToProps, null, null, { withRef: true })(TripBlockageForm);

export default TripBlockageForm;

/**
 * Opens a Blockage Form Modal for creating / updating trip blockages
 */
export const openBlockageModal = props => {
    const { existingBlockage, start, end, selectedCompany, t } = props;

    props.openModal({
        component: ParentForm,
        componentProps: {
            reducerIndex: selectedCompany,
            isCreate: existingBlockage === undefined,
            mainEntityProps: {
                entity: Entities.TRIP_BLOCKAGE,
                id: existingBlockage ? existingBlockage.id : undefined,
            },
            childForm: {
                component: TripBlockageForm,
                props: {
                    companyId: selectedCompany,
                    start: start,
                    end: end,
                },
            },
        },
        title: existingBlockage ? existingBlockage.name : t('blockage.new'),
    });
};
